import React from "react";
import { InputText } from "primereact/inputtext";
import { getIn, setIn } from "../../utils";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";

export const EmailField = ({ values, label, name, help, placeholder }: any) => {
  const dispatch = useDispatch();
  const value = getIn(values, name, "");

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.target.value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <>
      <div className="p-field p-grid">
        <label
          htmlFor={name}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
        <div className="p-col">
          <InputText
            id={name}
            name={name}
            value={value}
            style={{ width: 400 }}
            type={"email"}
            tooltip={help}
            placeholder={placeholder}
            tooltipOptions={{ position: "right" }}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
};
