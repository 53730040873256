import React from "react";
import { connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Card as PrimeCard } from "primereact/card";

import { updateCurrentCardAction } from "../../../store/application";

import "./section.scss";
import {
  arrayHelpers,
  getFileIconFromType,
  getIn,
  setIn,
} from "../../../utils";
import { TextField } from "../../../components/inputs";
import { Button } from "primereact/button";
import { DocumentsUploader } from "../../../components/uploaders/DocumentsUploader";
import { Card } from "../../../models/card";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";

export interface DownloadsSectionProps extends WithTranslation {
  card: Card;
  name: string;
  updateCard?: any;
}

const SortableList = SortableContainer(
  ({
    items,
    name,
    card,
    onCardNameChange,
    onAddDocuments,
    onDelete,
    t,
  }: any) => {
    return (
      <>
        <DocumentsUploader onAddDocuments={onAddDocuments} />
        <ul style={{ listStyle: "none", padding: 0 }}>
          {items?.map((value: any, index: number) => {
            return (
              <SortableItem
                t={t}
                name={name}
                card={card}
                onCardNameChange={onCardNameChange}
                onDelete={onDelete}
                gkindex={index}
                key={`item-${index}`}
              />
            );
          })}
        </ul>
      </>
    );
  }
);

const SortableItem = SortableElement(
  ({ name, gkindex, card, onDelete, t }: any) => {
    return (
      <li
        style={{
          listStyle: "none",
          margin: 10,
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <PrimeCard
          style={{
            padding: "20px",
            textAlign: "left",
            backgroundColor: "#f5f5f5",
          }}
        >
          <TextField
            label={t("editor.name")}
            name={`${name}.DownloadsData[${gkindex}].Description`}
            values={card}
          />
          <TextField
            label={t("editor.file")}
            name={`${name}.DownloadsData[${gkindex}].Original`}
            values={card}
            disabled={true}
          />
          <Button
            label=""
            className="p-button-danger p-button-text"
            onClick={() => {
              onDelete(gkindex);
            }}
          >
            <span>{t("editor.delete")}</span>
          </Button>
        </PrimeCard>
      </li>
    );
  }
);

class _DownloadsSection extends React.Component<DownloadsSectionProps, {}> {
  constructor(props: DownloadsSectionProps) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleOnAddDocuments = this.handleOnAddDocuments.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.onCardNameChange = this.onCardNameChange.bind(this);
  }

  onSortEnd() {}

  onCardNameChange(index: number, value: string) {
    const { updateCard, card, name } = this.props;
    const nc = setIn(
      card,
      `${name}.DownloadsData[${index}].Description`,
      value
    );
    updateCard(nc);
  }

  handleOnAddDocuments(files: any) {
    const { card, name, updateCard } = this.props;
    const downloads = getIn(card, `${name}.DownloadsData`);
    const nd = [
      ...(downloads || []),
      ...(files?.map((f: any) => ({
        Description: f.name,
        Icon: getFileIconFromType(f.type),
        Type: f.type,
        Original: f.name,
        Url: f.content,
        Size: f.size,
      })) ?? []),
    ];

    const nc = setIn(card, `${name}.DownloadsData`, nd);
    updateCard(nc);
  }

  handleOnDelete(index: number) {
    const { card, name, updateCard } = this.props;
    let downloads = getIn(card, `${name}.DownloadsData`);
    const nd = arrayHelpers.remove(downloads, index);
    const nc = setIn(card, `${name}.DownloadsData`, nd);

    updateCard(nc);
  }

  render() {
    const { card, name, t } = this.props;
    const downloads = getIn(card, `${name}.DownloadsData`);

    return (
      <div className="p-section-container">
        <div className="p-field p-grid">
          <div className="p-col">
            <SortableList
              t={t}
              items={downloads}
              card={card}
              name={name}
              onSortEnd={this.onSortEnd}
              onCardNameChange={this.onCardNameChange}
              //pressDelay={200}
              distance={10}
              onDelete={this.handleOnDelete}
              onAddDocuments={this.handleOnAddDocuments}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  updateCard: (card: Card) => dispatch(updateCurrentCardAction(card)),
});

export const DownloadsSection = compose(
  withTranslation(),
  connect(null, mapDispatchToProps)
)(_DownloadsSection);
