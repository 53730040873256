import { InputText } from "primereact/inputtext";
import { connect } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { Card } from "../../models/card";
import React from "react";
import { gkb } from "../../service/gkb";

export interface AliasFieldProps {
  values: Card | null;
  label: string;
  updateCard?: any;
  onAliasChanged: any;
}

interface State {
  error: string;
  touched: boolean;
  validating: boolean;
  valid: boolean;
  currentName: string;
}

class _AliasField extends React.Component<AliasFieldProps, State> {
  private ht: any;
  constructor(props: AliasFieldProps) {
    super(props);

    const { values } = props;
    this.state = {
      error: "",
      touched: false,
      valid: true,
      validating: false,
      currentName: values?.Alias ?? "",
    };

    this.ht = null;
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {}

  async validateAlias(value: string) {
    const { values } = this.props;
    const reservedAlias = [
      "cardid",
      "resourceid",
      "home",
      "login",
      "not-found",
      "ekard",
      "app",
    ];

    if (values && value === values.Alias) {
      return true;
    }

    if (reservedAlias.indexOf(value) > -1) {
      return false;
    }

    const response = await gkb.LoadCardByAlias(value);

    if (response && response.status === 200) {
      const card = response.data;
      if (card && card.ID !== values?.ID) {
        return false;
      }
    }

    return true;
  }

  async handleOnChange(e: any) {
    const nc = { ...this.props.values };
    const newAlias = e.target.value;
    this.setState({ currentName: newAlias });
    this.props.onAliasChanged(newAlias, true);

    if (this.ht !== null) {
      clearTimeout(this.ht);
    }

    this.ht = setTimeout(async () => {
      this.setState({ validating: true });
      this.ht = null;

      const v = await this.validateAlias(newAlias);
      if (v) {
        nc.Alias = newAlias;
        const response = { ...nc };
        this.setState({ valid: true, validating: false, error: "" });
        this.props.updateCard(response);
        this.props.onAliasChanged(newAlias, false);
      } else {
        this.setState({
          valid: false,
          validating: false,
          error: "Este alias ya está asignado. Por favor, elige otro distinto.",
        });
        this.props.onAliasChanged(newAlias, true);
      }
    }, 1000);
  }

  render() {
    const { error, validating, valid, currentName } = this.state;

    return (
      <>
        <div className="p-field p-grid">
          <label
            htmlFor="Alias"
            className="p-col-fixed"
            style={{ width: "120px" }}
          >
            Alias
          </label>
          <div className="p-col">
            <InputText
              id="Alias"
              name="Alias"
              keyfilter={/^[a-z0-9]+$/}
              // @ts-ignore
              validate={this.validateAlias}
              style={{ width: 400 }}
              className={error ? "p-invalid" : ""}
              value={currentName}
              onChange={this.handleOnChange}
            />
            {validating && (
              <i
                className="pi pi-spin pi-spinner"
                style={{ marginLeft: 5 }}
              ></i>
            )}
            {valid && !validating && (
              <i
                className="pi pi-check"
                style={{ marginLeft: 5, color: "#047857" }}
              ></i>
            )}
          </div>
        </div>
        <div className="p-field">
          {error && (
            <small
              id="alias-error-help"
              className="p-invalid p-d-block"
              style={{ marginBottom: 20 }}
            >
              {error}
            </small>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  updateCard: (card: Card) => dispatch(updateCurrentCardAction(card)),
});

export const AliasField = connect(null, mapDispatchToProps)(_AliasField);
