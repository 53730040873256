import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "./types";
import { Resource } from "../../models/resource/ResourceDTO";
import { Card } from "../../models/card";
import { Company } from "../../models/company/CompanyDTO";

export const applicationInitialState: ApplicationState = {
  loading: false,
  modalMessage: null,
  modalTitle: null,
  error: null,
  currentResource: null,
  currentCard: null,
  currentCompany: null,
  currentSection: "config",
  user: null,
};

export const applicationReducer = createReducer(applicationInitialState, {
  "APPLICATION/UPDATE_USER": (
    state: ApplicationState,
    action: PayloadAction<any>
  ) => ({ ...state, user: action.payload }),
  "APPLICATION/SET_CURRENT_RESOURCE": (
    state: ApplicationState,
    action: PayloadAction<Resource | null>
  ) => ({ ...state, currentResource: action.payload.Resource }),
  "APPLICATION/SET_CURRENT_SECTION": (
    state: ApplicationState,
    action: PayloadAction<string>
  ) => ({ ...state, currentSection: action.payload }),
  "APPLICATION/LOAD_RESOURCE_REQUEST": (state: ApplicationState) => {
    return {
      ...state,
      loading: true,
      error: null,
      modalMessage: "Cargando recurso. Por favor, espere...",
      modalTitle: "Cargando recurso",
    };
  },
  "APPLICATION/LOAD_RESOURCE_SUCCESS": (
    state: ApplicationState,
    action: PayloadAction<Resource | null>
  ) => {
    return {
      ...state,
      loading: false,
      error: null,
      currentResource: action.payload.Resource,
      currentCard: action.payload.Card,
      currentCompany: action.payload.Company,
      modalMessage: null,
      modalTitle: null,
    };
  },
  "APPLICATION/LOAD_RESOURCE_ERROR": (
    state: ApplicationState,
    action: PayloadAction<string>
  ) => ({ ...state, loading: false, error: action.payload }),
  "APPLICATION/UPDATE_CURRENT_CARD": (
    state: ApplicationState,
    action: PayloadAction<Card>
  ) => ({ ...state, currentCard: action.payload }),
  "APPLICATION/UPDATE_CURRENT_COMPANY": (
    state: ApplicationState,
    action: PayloadAction<Company>
  ) => ({ ...state, currentCompany: action.payload }),
  "APPLICATION/SAVE_CARD_REQUEST": (state: ApplicationState) => ({
    ...state,
    loading: true,
    error: null,
    modalMessage: "Guardando tarjeta. Por favor, espere...",
    modalTitle: "Guardando tarjeta",
  }),
  "APPLICATION/SAVE_CARD_SUCCESS": (
    state: ApplicationState,
    action: PayloadAction<Card | null>
  ) => ({
    ...state,
    loading: false,
    error: null,
    currentCard: action.payload,
    modalMessage: null,
    modalTitle: null,
  }),
  "APPLICATION/SAVE_CARD_ERROR": (
    state: ApplicationState,
    action: PayloadAction<string>
  ) => ({ ...state, loading: false, error: action.payload }),
  "APPLICATION/SAVE_COMPANY_REQUEST": (state: ApplicationState) => ({
    ...state,
    loading: true,
    error: null,
    modalMessage: "Guardando empresa. Por favor, espere...",
    modalTitle: "Guardando empresa",
  }),
  "APPLICATION/SAVE_COMPANY_SUCCESS": (
    state: ApplicationState,
    action: PayloadAction<Company | null>
  ) => ({
    ...state,
    loading: false,
    error: null,
    currentCompany: action.payload,
    modalMessage: null,
    modalTitle: null,
  }),
  "APPLICATION/SAVE_COMPANY_ERROR": (
    state: ApplicationState,
    action: PayloadAction<string>
  ) => ({ ...state, loading: false, error: action.payload }),
});
