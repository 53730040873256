import { getIn, setIn } from "../../utils";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import React from "react";
import { LogoUploader } from "../uploaders/LogoUploader";
import { useTranslation } from "react-i18next";

export const ImageUploaderField = ({
  values,
  label,
  name,
  help,
  placeholder,
  round
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const value = getIn(values, name, "");

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.target.value ?? "");
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <div className="p-field p-grid">
      <label
        htmlFor="Contact.UserImage"
        className="p-col-fixed"
        style={{ width: "120px" }}
      >
        {label}
      </label>
      <div className="p-col">
        <LogoUploader
          value={value}
          id="Contact.UserImage"
          name="Contact.UserImage"
          style={{ width: 400 }}
          round={round}
          className={""}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
