import React from "react";

function LoopIcon({ color = "#000", width = 0.5 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      baseProfile="tiny"
      overflow="visible"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={width}
      >
        <path d="M2.8 12.1V9.6c0-1 .6-1.9 1.4-1.9h15.6"></path>
        <path d="M19.9 7.7L17.1 10.6"></path>
        <path d="M17.1 4.8L19.9 7.6"></path>
        <g>
          <path d="M21.2 11.9v2.5c0 1-.6 1.9-1.4 1.9H4.1"></path>
          <path d="M4.1 16.3L6.9 13.4"></path>
          <path d="M6.9 19.2L4.1 16.4"></path>
        </g>
      </g>
    </svg>
  );
}

export default LoopIcon;
