import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TextField } from "../../../components/inputs";
import { CardFactory } from "../../../models/card/CardFactory";
import { updateCurrentCardAction } from "../../../store/application";
import { getIn, setIn } from "../../../utils";
import { useTranslation } from "react-i18next";

import "./section.scss";

export const LinkSection = ({ card, name }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const data = getIn(card, `${name}.LinkData`);
    if (!data) {
      const nc = setIn(card, `${name}.LinkData`, CardFactory.newLinkData());
      dispatch(updateCurrentCardAction(nc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return (
    <div className="p-section-container">
      <TextField
        label={t("editor.link")}
        name={`${name}.LinkData.Url`}
        values={card}
      />
    </div>
  );
};
