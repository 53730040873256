export const readFile = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => reject("file reading was aborted");
    reader.onerror = () => reject("file reading has failed");
    reader.onload = () => {
      file.content = reader.result;
      resolve(file);
    };
    reader.readAsDataURL(file);
  });
};
