import React, { createRef } from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ColorField } from "../../components/inputs/ColorField";

import "./styles.css";
import "react-upload-gallery/dist/style.css"; // or scss
import { ImageGalleryField } from "../../components/imageGallery/ImageGallery";
import { Card as CardDTO, HeaderData } from "../../models/card";
import { ImageUploaderField } from "../../components/inputs/ImageUploaderField";
import { CheckboxField, DropdownField } from "../../components/inputs";
import { SliderEffects } from "../data/sliderEffects";
import { QuillEditorField } from "../../components/inputs/QuillEditor";
import { WithTranslation, withTranslation } from "react-i18next";

interface ConfigValues {
  Header: HeaderData | null;
}

interface HeaderEditorProps extends WithTranslation {
  onChange: any;
  card: CardDTO | null;
  isAdmin: boolean;
}

class _HeaderEditor extends React.Component<HeaderEditorProps, any> {
  private readonly initialValues: ConfigValues;
  private lastValues: ConfigValues;
  private formRef: any;

  constructor(props: HeaderEditorProps) {
    super(props);

    const { card } = this.props;
    this.initialValues = {
      Header: card ? { ...card.Header } : null,
    };

    this.formRef = createRef();
    this.lastValues = { ...this.initialValues };
  }

  render() {
    const { t, card, isAdmin = false } = this.props;

    if (!card?.Header) {
      return null;
    }

    return (
      <>
        <Card>
          <Accordion multiple activeIndex={0}>
            <AccordionTab header={t("editor.general")}>
              {card.Type === "master:corporate" && isAdmin &&
                <CheckboxField label={t("editor.block-header")} name="Header.Locked" id="Header.Locked" values={card} />
              }
              <ImageUploaderField
                values={card}
                label={t("editor.logo-image")}
                id="Header.Logo"
                name="Header.Logo"
                style={{ width: 400 }}
                round={false}
                cropperjsOptions={{
                  initialAspectRatio: 2,
                  aspectRatio: 2
                }}
                className={""}
              />
              <p style={{ textAlign: "left", marginBottom: "20px", fontStyle: "italic" }}>{t("editor.user-image-warning")}</p>

              <ColorField
                label={t("editor.background-color")}
                name="Header.BackgroundColor"
                values={card}
                advanced={isAdmin}
              />
              <ColorField
                label={t("editor.icons-color")}
                name="Header.IconsColor"
                values={card}
                advanced={false}
              />
              <QuillEditorField
                label={t("editor.title")}
                name="Header.Title"
                singleLine={true}
                values={card}
                backgroundColor={card?.Header.BackgroundColor}
              />
              <QuillEditorField
                label={t("editor.subtitle")}
                name="Header.Subtitle"
                singleLine={true}
                values={card}
                backgroundColor={card?.Header.BackgroundColor}
              />
            </AccordionTab>
            <AccordionTab header={t("editor.carousel")}>
              <DropdownField
                items={SliderEffects}
                label={t("editor.effect")}
                name="Header.Slider.Effect"
                values={card}
                help={t("editor.tooltip-effect")}
              />
              <CheckboxField label={t("editor.autoplay")} name="Header.Slider.Autoplay" values={card} />
              <p style={{ textAlign: "left", marginBottom: "20px", fontStyle: "italic" }}>{t("editor.sliders-image-warning")}</p>
              <ImageGalleryField name={"Header.Slider.Slides"} values={card} showLink={false} showFullScreen={false} />
            </AccordionTab>
            <AccordionTab header={t("editor.buttons")}>
              <CheckboxField label={t("editor.expand-buttons")} name="Header.ExpandButtons" id="Header.ExpandButtons" values={card} />
              <ColorField
                label={t("editor.buttons-background")}
                name="Header.ButtonsBackground"
                values={card}
                advanced={isAdmin}
              />

            </AccordionTab>
          </Accordion>
        </Card>
      </>
    );
  }
}

export const HeaderEditor = withTranslation()(_HeaderEditor);
