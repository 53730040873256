export const Sections = [
  {
    value: 10,
    label: "editor.profile",
    Icon: "fa-user-circle-o",
  },
  {
    value: 1,
    label: "editor.form",
    Icon: "fa-file-label-o",
  },
  {
    value: 2,
    label: "editor.gallery",
    Icon: "fa-picture-o",
  },
  {
    value: 3,
    label: "editor.custom-content",
    Icon: "fa-font",
  },
  {
    value: 4,
    label: "editor.link",
    Icon: "fa-external-link",
  },
  {
    value: 7,
    label: "editor.downloads",
    Icon: "fa-cloud-download",
  },
];
