export const DirectAccess = [
  { value: "mobile", label: "editor.mobile", icon: "fa-mobile" },
  { value: "sms", label: "SMS", icon: "fa-commenting-o" },
  {
    value: "corporate-phone",
    label: "editor.company-phone",
    icon: "fa-phone",
  },
  { value: "email", label: "Email", icon: "fa-envelope" },
  { value: "whatsapp", label: "Whatsapp", icon: "fa-whatsapp" },
  { value: "facebook", label: "Facebook", icon: "fa-facebook" },
  { value: "linkedin", label: "Linkedin", icon: "fa-linkedin" },
  { value: "twitter", label: "Twitter", icon: "fa-twitter" },
  { value: "youtube", label: "Youtube", icon: "fa-youtube" },
  { value: "web", label: "Web", icon: "fa-internet-explorer" },
  { value: "instagram", label: "Instagram", icon: "fa-instagram" },
  { value: "telegram", label: "Telegram", icon: "fa-telegram" },
  { value: "tiktok", label: "TikTok", icon: "fa-telegram" },
  { value: "reddit", label: "Reddit", icon: "fa-telegram" },
];
