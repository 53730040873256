import { Card } from "../../models/card";
import { ApplicationState } from "./types";
import { Company } from "../../models/company/CompanyDTO";
import { ResourceDTO } from "../../models/resource/ResourceDTO";

export const getCurrentCard = (state: ApplicationState): Card | null => {
  return state.currentCard;
};

export const getCurrentCompany = (state: ApplicationState): Company | null => {
  return state.currentCompany;
};

export const getCurrentUserId = (state: ApplicationState): number => {
  return state.user?.ID;
};

export const getCurrentUser = (state: ApplicationState): any => {
  return state.user;
};

export const getCurrentResource = (
  state: ApplicationState
): ResourceDTO | null => {
  return state.currentResource;
};

export const isLoading = (state: ApplicationState): boolean => {
  return state.loading;
};

export const getModalInfo = (
  state: ApplicationState
): { title: string; message: string } => {
  return { title: state.modalTitle ?? "", message: state.modalMessage ?? "" };
};

export const getCurrentSection = (state: ApplicationState): string => {
  return state.currentSection;
};
