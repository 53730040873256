import React, { useEffect, useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./menu.css";
import { Card, SectionData } from "../../../models/card";
import { arrayHelpers } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentSection,
  setCurrentSectionAction,
} from "../../../store/application";
import { StoreState } from "../../../store";
import { CardFactory } from "../../../models/card/CardFactory";
import { htmlToPlainText } from "../../../models/textversion";
import { useTranslation } from "react-i18next";
import { MenuItem } from "./menuItems";

interface CardEditorMenuProps {
  onChange: any;
  card: Card | null;
}

const activateStyle = {
  fontWeight: "bold",
  color: "rgba(4, 120, 87) !important",
};

export const ProfessionalMenu = ({ card, onChange }: CardEditorMenuProps) => {
  const { t } = useTranslation();
  const { Type = "" } = card || { Type: "" };

  const currentSection = useSelector((state: StoreState) =>
    getCurrentSection(state.application)
  );
  const dispatch = useDispatch();

  const handleChangeSelected = (eventKey: string) => {
    dispatch(setCurrentSectionAction(eventKey));
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newSections = arrayHelpers.move(sections, oldIndex, newIndex);
    setSections(newSections);

    const nc = { ...card };
    nc.Sections = [...newSections.map((s: any) => s.data)];

    onChange(nc);
  };

  const maxPagesReached = () => {
    // if (card?.Config?.MaxPagesNumber) {
    //   if (card?.Sections?.length >= card.Config?.MaxPagesNumber) {
    //     return true;
    //   }
    // }

    return false;
  };

  const handleNewSection = () => {
    if (maxPagesReached()) {
      return;
    }

    const newSection = CardFactory.newSection();
    if (card?.Sections) {
      newSection.Order = card.Sections.length + 1;
      const nc = {
        ...card,
        Sections: [...card.Sections, newSection],
      };

      onChange(nc);
      setTimeout(() => {
        handleChangeSelected(`section:${newSection.SectionID}`);
      }, 0);
    }
  };

  const items = [
    {
      label: "",
      items: [
        {
          className: "menu-configuration",
          label: t("editor.configuration"),
          style: currentSection === "config" ? activateStyle : {},
          command: () => {
            handleChangeSelected("config");
          },
        },
        {
          className: "menu-header",
          label: t("editor.header"),
          style: currentSection === "header" ? activateStyle : {},
          command: () => {
            handleChangeSelected("header");
          },
        },
        {
          className: "menu-footer",
          label: t("editor.footer"),
          style: currentSection === "footer" ? activateStyle : {},
          command: () => {
            handleChangeSelected("footer");
          },
        },
      ],
    },
  ];

  const [sections, setSections] = useState<any>([]);
  useEffect(() => {
    if (card?.Sections) {
      setSections(
        card.Sections.filter((s: SectionData) => !s.MasterOverwrite).map(
          (s: SectionData) => ({
            index: s.SectionID,
            label: htmlToPlainText(s.Button.Text),
            data: s,
          })
        )
      );
    }
  }, [card]);

  const maxPages = maxPagesReached();

  return (
    <div
      style={{
        minHeight: "calc(100vh - 300px)",
        borderRight: "border-right: 1px solid #ddd",
      }}
    >
      {Type !== "master:fidelium" && (
        <div className="menu-section-1">
          <Menu model={items} />
        </div>
      )}
      {Type === "master:fidelium" && (
        <p className={"p-4"}>{t("editor.fidelium-section-info")}</p>
      )}
      <h2 className="pmenu-h2">{t("editor.sections")}</h2>
      <SortableList
        items={sections}
        onSortEnd={onSortEnd}
        distance={10}
        lockAxis={"y"}
        currentSection={currentSection}
        card={card}
        handleChangeSelected={handleChangeSelected}
        onChange={onChange}
      />

      <Button
        disabled={maxPages}
        label={t("editor.new-section")}
        className={`p-button button-new-section p-button-raised`}
        style={{
          marginBottom: 20,
          color: maxPages ? "gray" : "",
          opacity: maxPages ? 0.2 : 1,
        }}
        onClick={handleNewSection}
      />
    </div>
  );
};

const SortableItem = SortableElement(
  ({ value, handleChangeSelected, currentSection, card, onChange }: any) => {
    const selected = `section:${value.index}` === currentSection;
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <MenuItem
        section={value.data}
        selected={selected}
        card={card}
        onChangeSelected={handleChangeSelected}
        onChange={onChange}
      />
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleChangeSelected, currentSection, card, onChange }: any) => {
    return (
      <ul style={{ listStyle: "none", padding: "0 10px", overflowY: "auto" }}>
        {items.map((value: any, index: number) => (
          <SortableItem
            handleChangeSelected={handleChangeSelected}
            key={`item-${index}`}
            index={index}
            value={value}
            currentSection={currentSection}
            card={card}
            onChange={onChange}
          />
        ))}
      </ul>
    );
  }
);
