import React, { createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import cookie from "js-cookie";

import "./cardEditor.css";
import { CardEditorMenu } from "../components/menu/menu";
import { Card, SectionData } from "../../models/card";
import { ConfigEditor } from "../components/configEditor";
import { Content, LayoutWrapper, Sidebar } from "../../components/layouts";
import { HeaderEditor } from "../components/headerEditor";
import { FooterEditor } from "../components/footerEditor";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import {
  isLoading,
  setCurrentSectionAction,
  getCurrentSection,
  loadResourceAction,
  updateCurrentCardAction,
  saveCardAction,
  getCurrentCard,
  getCurrentUser,
  getCurrentUserId,
  updateUserAction,
} from "../../store/application";
import { Button } from "primereact/button";
import { SectionEditor } from "../components/sectionEditor";
import { htmlToPlainText } from "../../models/textversion";
import { compose } from "redux";
import { withRouter } from "react-router";
import { TutorComponent } from "../../tutor/TutorComponent";
import { gkb } from "../../service/gkb";
import { cardPreviewBase } from "../../utils";

interface State {
  expand: boolean;
  error: string;
  loading: boolean;
  saving: boolean;
  isError: boolean;
  showTutor: boolean;
}

interface Props extends WithTranslation {
  currentSection?: string;
  currentCard?: Card | null;
  userId: number;
  user: any;
  loading?: boolean;
  setCurrentSection?: any;
  loadResource?: any;
  saveCard?: any;
  updateCard?: any;
  updateUser?: any;
  language: string;
  match?: any;
  location?: any;
  history?: any;
}

// noinspection SillyAssignmentJS
class _CardEditor extends React.Component<Props, State> {
  private ifRef = createRef<HTMLIFrameElement>();
  private id: string;
  private firstRun = true;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      expand: false,
      error: "",
      isError: false,
      showTutor: false,
    };

    this.id = this.props.match.params.id;
    this.handleCardChange = this.handleCardChange.bind(this);
    this.handleCardSave = this.handleCardSave.bind(this);
    this.handleAliasChanged = this.handleAliasChanged.bind(this);
    this.handlePostMessage = this.handlePostMessage.bind(this);
    this.handleTutorClose = this.handleTutorClose.bind(this);
    this.handleSendAssignationEmail = this.handleSendAssignationEmail.bind(this);
  }

  handleSendAssignationEmail() {
      gkb.AssignCard(this.props.currentCard).then((res) => {
        window.dispatchEvent(
          new CustomEvent("General.showNotification", { detail: { message: "editor.save-success", type: "success" } })
        );
      });
  }

  async componentDidMount() {
    const { loadResource, setCurrentSection, i18n, language } = this.props;
    window.addEventListener("message", this.handlePostMessage, false);
    setCurrentSection("config");
    await i18n.changeLanguage(language);
    loadResource(this.id);
    this.setState({ loading: false });
  }

  handlePostMessage(event: any) {
    if (typeof event.data === "string") {
      if (event.data === "reload") {
        window.location.reload();
        return;
      }
      if (event.data.startsWith("section:")) {
        if (event.data !== "section:0") {
          this.props.setCurrentSection(event.data);
        } else if (this.props.currentCard.Type === "card:professional") {
          this.props.setCurrentSection("config");
        }
      }
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const { currentSection, user } = this.props;

    if (
      prevProps.loading === true &&
      this.props.loading === false &&
      this.props.currentCard
    ) {
      if (this.ifRef?.current) {
        this.ifRef.current.contentWindow.postMessage("reload", "*");
      }
      const evt = new CustomEvent("General.SetSpinner", { detail: false });
      window.dispatchEvent(evt);

      if (this.firstRun) {
        const st = cookie.get("gkb-editor-tutorial");
        if (!st || st === "0") {
          this.setState({ showTutor: true });
        }
        this.firstRun = false;
      }
    }

    if (prevProps.currentSection !== currentSection) {
      this.gotoSection(currentSection);
    }

    if (prevProps.language !== this.props.language) {
      const { i18n, language } = this.props;
      await i18n.changeLanguage(language);
    }
  }

  getPreviewUrl(cardId: number) {
    return `${cardPreviewBase}/cardid/${cardId}&ts=${Date.now()}`;
  }

  gotoSection(section: string) {
    if (section.startsWith("section:")) {
      this.ifRef.current.contentWindow.postMessage(section, "*");
    } else {
      this.ifRef.current.contentWindow.postMessage("section:0", "*");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handlePostMessage, false);
  }

  private handleAliasChanged(alias: string, error: boolean) {
    this.setState({ isError: error });
  }

  private async handleCardChange(changes: any) {
    const { updateCard } = this.props;
    updateCard(changes);
  }

  private handleCardSave() {
    const { saveCard, loading } = this.props;
    if (!loading) {
      saveCard(this.props.currentCard);
    }
  }

  isAdmin(): boolean {
    const { currentCard, userId } = this.props;
    return currentCard?.OwnerId === userId;
  }

  isUser(): boolean {
    const { currentCard, userId } = this.props;
    return this.isAdmin() || currentCard?.UserId === userId;
  }

  findCardById(id: string) {
    const { currentCard } = this.props;
    if (currentCard?.Sections) {
      for (let i = 0; i < currentCard.Sections.length; i++) {
        if (currentCard.Sections[i].SectionID === id) {
          return currentCard.Sections[i];
        }
      }
    }
  }

  handleTutorClose() {
    this.setState({ showTutor: false });
    const user = { ...this.props.user, EditorTutorialViewed: true };
    this.props.updateUser(user);
    cookie.set("gkb-editor-tutorial", "1");
    gkb
      .UpdateApiResource({
        name: "accounts",
        id: user.ID,
        body: { EditorTutorialViewed: true },
      })
      .then()
      .catch();
  }

  renderSubBreadcrumbs() {
    const { currentCard = null, t, history } = this.props;
    return (
      <div className="text-left pb-4">
        <ul
          className={"breadcrumbs w-full flex text-left  text-lg"}
          style={{ color: "#FFFDED" }}
        >
          <li
            className={"underline hover:opacity-50 cursor-pointer"}
            onClick={() => {
              history.push(`/my-account/cards/`);
            }}
          >
            {t("editor.my-cards")}
          </li>
          { this.isAdmin() &&
            <>
          <li className={"px-2"}>{" / "}</li>
          <li
            className={"underline hover:opacity-50 cursor-pointer"}
            onClick={() => {
              history.push(
                `/my-account/cards/${currentCard.CompanyID}/subordinates`
              );
            }}
          >
            {t("editor.subordinates-company") + ` #${currentCard.CompanyID}`}
          </li>
            </>
        }
          <li className={"px-2"}>{" / "}</li>
          <li>
            {t("editor.card") + ` #${currentCard.ID}`}
            <span className={"italic mx-2"}>{`(${t(
              "editor." + currentCard.Type.replace(":", "-")
            )})`}</span>
          </li>
          <div className={"flex-1 text-right"}>
            <li
              className={"underline hover:opacity-50 cursor-pointer"}
              onClick={() => {
                history.push(
                  `/editor/template/${currentCard.Mastercard.Resource.ID}`
                );
              }}
            >
              {t("editor.goto-template")}
            </li>
          </div>
        </ul>
        {currentCard.Assignation.Email !== "" && (
          <div className="text-white text-sm">
            Tarjeta asignada a{" "}
            <span
              className={"italic"}
            >{`${currentCard.Assignation.Email}`}</span>
          </div>
        )}
        <div className={"w-full text-right"} style={{ flex: 1 }}>
          <i
            className={
              "text-white pr-2 tutor-icon-circle cursor-pointer hover:opacity-60 fal fa-info-circle text-2xl"
            }
            onClick={() => {
              this.setState({ showTutor: true });
            }}
          />
        </div>
      </div>
    );
  }

  renderProfBreadcrumbs() {
    const { currentCard = null, t, history } = this.props;

    return (
      <ul
        className={"breadcrumbs w-full h-10 flex text-left pb-4 text-lg"}
        style={{ color: "#FFFDED" }}
      >
        {" "}
        <li
          className={"underline hover:opacity-50 cursor-pointer"}
          onClick={() => {
            history.push(`/my-account/cards/`);
          }}
        >
          {t("editor.my-cards")}
        </li>
        <li className={"px-2"}>{" / "}</li>
        <li>
          {t("editor.card") + ` #${currentCard.ID} `}
          <span className={"italic mx-2"}>{`(${t(
            "editor." + currentCard.Type.replace(":", "-")
          )})`}</span>
        </li>
        <div className={"w-full text-right"} style={{ flex: 1 }}>
          <i
            className={
              "pr-2 tutor-icon-circle cursor-pointer hover:opacity-60 fal fa-info-circle text-2xl"
            }
            onClick={() => {
              this.setState({ showTutor: true });
            }}
          />
        </div>
      </ul>
    );
  }


  render() {
    const {
      currentCard = null,
      t,
      currentSection = "",
      user,
      saveCard,
    } = this.props;
    const { showTutor } = this.state;
    const sectionId = currentSection.split(":")[1];
    const sectionData = this.findCardById(sectionId);

    return (
      <>
        <TutorComponent
          tutorialID={1}
          show={showTutor}
          onClose={this.handleTutorClose}
        />
        <LayoutWrapper>
          <Sidebar>
            <CardEditorMenu
              card={currentCard}
              onChange={this.handleCardChange}
              isAdmin={this.isAdmin()}
              isUser={this.isUser()}
            />
          </Sidebar>
          {currentCard && (
            <>
              <Content
                className={`${
                  currentCard.Type === "card:professional"
                    ? "bg-color-professional"
                    : currentCard.Type === "sub:fidelium"
                    ? "bg-color-sub-fidelium"
                    : currentCard.Type === "sub:corporate"
                    ? "bg-color-sub-corporate"
                    : ""
                } overflow-y-auto`}
              >
                {currentCard?.Type?.startsWith("sub:") &&
                  this.renderSubBreadcrumbs()}
                {currentCard?.Type?.startsWith("card:professional") &&
                  this.renderProfBreadcrumbs()}

                <div className="card-editor-header">
                  {currentSection === "config" && (
                    <h3 className={"text-white text-2xl"}>
                      {t("editor.general")}
                    </h3>
                  )}
                  {currentSection === "header" && (
                    <h3 className={"text-white text-2xl"}>
                      {t("editor.header")}
                    </h3>
                  )}
                  {currentSection === "footer" && (
                    <h3 className={"text-white text-2xl"}>
                      {t("editor.footer")}
                    </h3>
                  )}
                  {currentSection.startsWith("section:") && sectionData && (
                    <h3 className={"text-white text-2xl"}>
                      Sección {htmlToPlainText(sectionData.Button.Text)}
                    </h3>
                  )}
                  <Button
                    label={t("editor.save")}
                    className="tutor-button-save p-button-raised"
                    onClick={this.handleCardSave}
                    loading={this.props.loading}
                    disabled={this.state.isError}
                  />
                </div>
                {currentSection === "config" && (
                  <ConfigEditor
                    card={currentCard}
                    onChange={this.handleCardChange}
                    isAdmin={this.isAdmin()}
                    isUser={this.isUser()}
                    onAliasChanged={this.handleAliasChanged}
                    onSendAssignationEmail={this.handleSendAssignationEmail}
                    saveCard={saveCard}
                  />
                )}
                {currentSection === "header" && (
                  <HeaderEditor
                    card={currentCard}
                    onChange={this.handleCardChange}
                    isAdmin={this.isAdmin()}
                  />
                )}
                {currentSection === "footer" && (
                  <FooterEditor
                    card={currentCard}
                    onChange={this.handleCardChange}
                    isAdmin={this.isAdmin()}
                  />
                )}
                {currentSection.startsWith("section:") &&
                  currentCard.Sections.map((s: SectionData) => (
                    <SectionEditor
                      key={s.SectionID}
                      card={currentCard}
                      section={s.SectionID}
                      onChange={this.handleCardChange}
                      visible={currentSection === `section:${s.SectionID}`}
                      isAdmin={this.isAdmin()}
                    />
                  ))}
              </Content>
              <Sidebar width={500}>
                <iframe
                  className="tutor-card-preview"
                  id="gkiframe"
                  ref={this.ifRef}
                  title="GreenKard"
                  src={`${cardPreviewBase}/cardid/${currentCard?.ID}`}
                  style={{
                    width: "100%",
                    height: "calc(100vh - 80px)",
                  }}
                  onLoad={() => {
                    if (currentSection.startsWith("section:")) {
                      setTimeout(() => {
                        this.gotoSection(currentSection);
                      }, 1000);
                    }
                  }}
                />
              </Sidebar>
            </>
          )}
        </LayoutWrapper>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState) => {
  const { application } = state;
  return {
    currentCard: getCurrentCard(application),
    currentSection: getCurrentSection(application),
    loading: isLoading(application),
    userId: getCurrentUserId(application),
    user: getCurrentUser(application),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentSection: (section: string) =>
    dispatch(setCurrentSectionAction(section)),
  loadResource: (resourceId: number) =>
    dispatch(loadResourceAction(resourceId)),
  updateCard: (card: Card) => dispatch(updateCurrentCardAction(card)),
  saveCard: (card: Card) => dispatch(saveCardAction(card)),
  updateUser: (user: any) => dispatch(updateUserAction(user)),
});

// @ts-ignore
export const CardEditor = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(_CardEditor as any));
