import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { getIn, setIn } from "../../utils";

export const TextArea = ({
  values,
  label,
  name,
  help,
  placeholder = "",
  style = {},
}: any) => {
  const dispatch = useDispatch();
  const value = getIn(values, name, "");

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.target.value);
    dispatch(updateCurrentCardAction(response));
  };

  const finalStyle = { width: "400px", ...style };

  return (
    <>
      <div className="p-field p-grid">
        <label
          htmlFor={name}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
        <div className="p-col">
          <InputTextarea
            id={name}
            name={name}
            value={value}
            style={finalStyle}
            rows={5}
            tooltip={help}
            tooltipOptions={{ position: "right" }}
            onChange={handleOnChange}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
  );
};
