import { PayloadAction } from "@reduxjs/toolkit";
import { Card } from "../../models/card";
import { Company } from "../../models/company/CompanyDTO";

export const SET_CURRENT_RESOURCE = "APPLICATION/SET_CURRENT_RESOURCE";

export const SET_CURRENT_SECTION = "APPLICATION/SET_CURRENT_SECTION";
export const LOAD_RESOURCE_REQUEST = "APPLICATION/LOAD_RESOURCE_REQUEST";
export const LOAD_RESOURCE_SUCCESS = "APPLICATION/LOAD_RESOURCE_SUCCESS";
export const LOAD_RESOURCE_ERROR = "APPLICATION/LOAD_RESOURCE_ERROR";

export const UPDATE_CURRENT_CARD = "APPLICATION/UPDATE_CURRENT_CARD";
export const SAVE_CARD_REQUEST = "APPLICATION/SAVE_CARD_REQUEST";
export const SAVE_CARD_SUCCESS = "APPLICATION/SAVE_CARD_SUCCESS";
export const SAVE_CARD_ERROR = "APPLICATION/SAVE_CARD_ERROR";

export const UPDATE_CURRENT_COMPANY = "APPLICATION/UPDATE_CURRENT_COMPANY";
export const SAVE_COMPANY_REQUEST = "APPLICATION/SAVE_COMPANY_REQUEST";
export const SAVE_COMPANY_SUCCESS = "APPLICATION/SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_ERROR = "APPLICATION/SAVE_COMPANY_ERROR";

export const UPDATE_USER = "APPLICATION/UPDATE_USER";

/* Resource Actions */
export const setCurrentSectionAction = (
  section: string
): PayloadAction<string> => ({
  type: SET_CURRENT_SECTION,
  payload: section,
});

export const loadResourceAction = (
  resourceId: number
): PayloadAction<number> => ({
  type: LOAD_RESOURCE_REQUEST,
  payload: resourceId,
});

/* Card Actions */
export const updateCurrentCardAction = (card: Card): PayloadAction<Card> => ({
  type: UPDATE_CURRENT_CARD,
  payload: card,
});

export const saveCardAction = (card: Card): PayloadAction<Card> => ({
  type: SAVE_CARD_REQUEST,
  payload: card,
});

/* Company Actions */
export const updateCurrentCompanyAction = (
  company: Company
): PayloadAction<Company> => ({
  type: UPDATE_CURRENT_COMPANY,
  payload: company,
});

export const saveCompanyAction = (card: Company): PayloadAction<Company> => ({
  type: SAVE_COMPANY_REQUEST,
  payload: card,
});

export const updateUserAction = (user: any): PayloadAction<any> => ({
  type: UPDATE_USER,
  payload: user,
});
