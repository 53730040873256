export const Fonts = [
  { value: "roboto", label: "Roboto" },
  { value: "akaya", label: "Akaya Telivigala" },
  { value: "lato", label: "Lato" },
  { value: "opensans", label: "Open Sans" },
  { value: "oswald", label: "Oswald" },
  { value: "ssans", label: "Source Sans Pro" },
  { value: "montserrat", label: "Montserrat" },
  { value: "ubuntu", label: "Ubuntu" },
  { value: "dosis", label: "Dosis" },
  { value: "merri", label: "Merriweather" },
  { value: "playfair", label: "Playfair Display" },
  { value: "lora", label: "Lora" },
  { value: "ptserif", label: "PT Serif" },
  { value: "sourceserif", label: "Source Serif" },
  { value: "arvo", label: "Arvo" },
  { value: "crimson", label: "Crimson" },
  { value: "zilla", label: "Zilla Slab" },
  { value: "ibm", label: "IBM Plex Serif" },
  { value: "libre", label: "Libre Baskerville" },
  { value: "slabo", label: "Slabo 27px" },
  { value: "ebgaramond", label: "EB Garamond" },
];
