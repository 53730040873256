import {
  AssignationData,
  ButtonData,
  Card,
  ContactData,
  CorporateData,
  FooterData,
  FormData,
  HeaderData,
  HtmlData,
  LinkData,
  SearchData,
  SectionData,
  Slide,
} from "./CardDTO";
import uniqid from "uniqid";

export class CardFactory {
  static newCorporateData(): CorporateData {
    return {
      Name: "",
      Address: "",
      Email: "",
      Url: "",
      Phone: "",
    };
  }

  static newFormData(): FormData {
    return {
      Text: "",
      EmailAddress: true,
      Name: false,
      NameR: false,
      Address: false,
      AddressR: false,
      LastName: false,
      LastNameR: false,
      Email: true,
      EmailR: false,
      Web: false,
      WebR: false,
      Corporate: false,
      CorporateR: false,
      Phone: false,
      PhoneR: false,
      Mobile: false,
      MobileR: false,
      Subject: false,
      SubjectR: false,
      Message: true,
    };
  }

  static newSection(): SectionData {
    return {
      ID: 0,
      SectionID: uniqid(),
      Order: 0,
      Button: CardFactory.newButtonData(),
      Type: -1,
      ProfileData: CardFactory.newContactData(),
      FormData: CardFactory.newFormData(),
      HtmlData: CardFactory.newHtmlData(),
      DownloadsData: [],
      GalleryData: [],
      LinkData: CardFactory.newLinkData(),
      ReadOnly: false,
      MasterOverwrite: false,
      Hidden: false,
    };
  }

  static newButtonData(): ButtonData {
    return {
      BackgroundColor: "#ffffff",
      Text: '<p class="ql-align-center">Nueva sección</p>',
    };
  }

  static newContactData(): ContactData {
    return {
      UserImage: "",
      FirstName: "",
      LastName: "",
      Company: "",
      Phone: "",
      Charge: "",
      CorporatePhone: "",
      Email: "",
      WebUrl: "",
      Address: "",
      Location: {
        lat: 0,
        lng: 0,
      },
      Social: {
        Facebook: "",
        Linkedin: "",
        Twitter: "",
        Instagram: "",
        Telegram: "",
        Youtube: "",
        Tiktok: "",
        Reddit: "",
      },
    };
  }

  static newSearchData(): SearchData {
    return {
      Public: false,
      Sector: "",
      Tags: [],
    };
  }

  static newHtmlData(): HtmlData {
    return { Text: "" };
  }

  static newLinkData(): LinkData {
    return { Url: "" };
  }

  static newHeaderData(): HeaderData {
    return {
      Logo: "",
      BackgroundColor: "",
      IconsColor: "",
      Title: "",
      Subtitle: "",
      Slider: {
        Effect: "",
        Slides: [],
      },
    };
  }

  static newFooterData(): FooterData {
    return { BackgroundColor: "", QuickAccess: [] };
  }

  static newAssignationData(): AssignationData {
    return { Email: "", Status: "available", Name: "" };
  }

  static clearCard(card: Card): Card {
    const newCard = JSON.parse(JSON.stringify(card));
    newCard.Alias = "";
    newCard.Language = "";
    newCard.Corporate = this.newCorporateData();
    newCard.Contact = this.newContactData();
    newCard.Search = this.newSearchData();
    newCard.Header = this.newHeaderData();
    newCard.Footer = this.newFooterData();
    newCard.Sections = [];
    newCard.Assignation = this.newAssignationData();
    newCard.CustomCss = "";
    newCard.CustomJs = "";

    return newCard;
  }
}
