import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { applicationInitialState, ApplicationState } from "./application";
import applicationSaga from "./application/sagas";
import rootReducer from "./reducer";

export interface StoreState {
  application: ApplicationState;
}

const storeInitialState: StoreState = {
  application: applicationInitialState,
};

const sagaMiddleware = createSagaMiddleware();
export let sagaTask = undefined;

export default function configureAppStore(
  preloadedState: StoreState = storeInitialState
) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
      }).concat(sagaMiddleware),
    preloadedState,
    enhancers: [],
  });

  if (process.env.NODE_ENV !== "production" && (module as any).hot) {
    (module as any).hot.accept("./reducer", () =>
      store.replaceReducer(rootReducer)
    );
  }

  sagaTask = sagaMiddleware.run(applicationSaga);
  return store;
}
