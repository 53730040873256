import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { getCookie } from "./service/cookies";

const backend = {
  loadPath: "/gks/api/locales/{{lng}}/{{ns}}",
  allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
  crossDomain: false,
  withCredentials: false,
  overrideMimeType: true,
  load: "currentOnly",
  customHeaders: {
    "X-GKBApiKey": process.env.APPLICATION_ID,
  },
  reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
};

const lng = getCookie("GK-LANGUAGE") || navigator.language.split("-")[0];

i18n
  .use(HttpApi)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // @ts-ignore
    backend,
    detection: {
      order: ["cookie"],
      lookupCookie: "GK-LANGUAGE",
      caches: ["cookie", "localStorage"],
    },
    lng,
    fallbackLng: "en",
    cleanCode: true,
    supportedLngs: ["en", "es", "pt", "fr", "de", "nl"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
