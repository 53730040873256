import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { GalleryUploader } from "../uploaders/GalleryUploader";
import { OverlayPanel } from "../primereact/components/overlaypanel/OverlayPanel";

export const UploaderPopup = ({ op, label, initialValue, onChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <OverlayPanel ref={op}>
      <div className="flex flex-col">
        {label && (
          <label htmlFor={"input-popup"} className="p-col-fixed w-full">
            {label}
          </label>
        )}
        <div className="flex">
          {value === "" && (
            <GalleryUploader
              value={value}
              id="video-cover"
              name="video-cover"
              style={{ width: 400 }}
              crop={false}
              className={""}
              onAddImage={(e) => {
                setValue(e.content);
                onChange(e.content);
              }}
            />
          )}
          {value !== "" && <img alt="cover" src={value} width={150} />}
          <Button
            label=""
            className="p-button-primary p-button-text"
            onClick={() => {
              onChange(value);
              op.current.hide();
            }}
          >
            <i className="fal fa-check text-xl" />
          </Button>
          <Button
            label=""
            className="p-button-danger p-button-text"
            onClick={(e) => {
              onChange("");
              op.current.hide();
            }}
          >
            <i className="fal fa-trash text-xl" />
          </Button>
        </div>
      </div>
    </OverlayPanel>
  );
};
