import React, { Suspense, useEffect } from "react";
import "./assets/themes/greenkard/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "cropperjs/dist/cropper.css";
import "./index.scss";
import App from "./App";
import Quill from "quill";
import configureAppStore, { sagaTask } from "./store";
import { Provider } from "react-redux";
import { applicationInitialState } from "./store/application";
import { Fonts } from "./pages/data/fonts";
import BgLoader from "./components/bgLoader/BgLoader";

const appToken = "";
const userId = "";

const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    if (e.defaultPrevented || !this.quill.isEnabled()) return;
    const range = this.quill.getSelection();
    let delta = new Delta().retain(range.index);
    const scrollTop = this.quill.scrollingContainer.scrollTop;

    this.container.focus();
    this.quill.selection.update("silent");
    setTimeout(() => {
      delta = delta.concat(this.convert()).delete(range.length);

      if (this.options.newLines === false) {
        delta.ops.map((op: any) => {
          if (!(typeof op.insert === "undefined")) {
            op.insert = op.insert.replace(/(\r\n|\n|\r)/gm, " ");
          }
          return op;
        });
      }

      this.quill.updateContents(delta, "user");
      this.quill.setSelection(delta.length() - range.length, "silent");
      this.quill.scrollingContainer.scrollTop = scrollTop;
      this.quill.focus();
    }, 1);
  }
}

const FontAttributor = Quill.import("attributors/class/font");
FontAttributor.whitelist = [...Fonts.map((f: any) => f.value)];
Quill.register(FontAttributor, true);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["18px", "16px", "20px"];
Quill.register(Size, true);

Quill.register("modules/clipboard", PlainClipboard, true);

export const Root = ({ user }: any) => {
  const store = configureAppStore({
    application: { ...applicationInitialState, user: user },
  });
  useEffect(() => {
    return () => {
      sagaTask.cancel();
    };
  }, []);

  return (
    <Suspense fallback={<BgLoader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  );
};
