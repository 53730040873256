import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0
import './cropModal.css';

export interface CropModalProps {
  show: boolean;
  files: any;
  onCancel: any;
  onAcept: any;
  croperjsOptions: any;
  outputOptions?: any;
  round: boolean;
  width: number;
  format?: 'blob' | 'base64';
}

interface State {
  currentImage: number;
  disableButton: boolean;
}

export class CropModal extends React.Component<CropModalProps, State> {
  private cropper: any;

  constructor(props: CropModalProps) {
    super(props);
    this.onCropperInit = this.onCropperInit.bind(this);
    this.onZoomIn = this.onZoomIn.bind(this);
    this.onZoomOut = this.onZoomOut.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.onRotateLeft = this.onRotateLeft.bind(this);
    this.onRotateRight = this.onRotateRight.bind(this);
    this.onAcept = this.onAcept.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      currentImage: 0,
      disableButton: false
    };
  }

  onCropperInit(cropper: any) {
    debugger
    this.cropper = cropper;
  }

  getCroppedDataUrl() {
    const { round = false, outputOptions } = this.props;
    const croppedCanvas = this.cropper.getCroppedCanvas(outputOptions);

    if (round) {
      const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      return roundedCanvas.toDataURL();
    } else {
      return croppedCanvas.toDataURL();
    }
  }

  getCroppedDataBlob(cb: any) {
    const { round = false, outputOptions } = this.props;
    const croppedCanvas = this.cropper.getCroppedCanvas(outputOptions);

    if (round) {
      const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      return roundedCanvas.toBlob(cb);
    } else {
      return croppedCanvas.toBlob(cb);
    }
  }

  async getCroppedData() {
    const { format = 'base64' } = this.props;
    return new Promise((resolve, reject) => {
      if (format === 'base64') {
        const content = this.getCroppedDataUrl();
        resolve(content);
      } else {
        this.getCroppedDataBlob((blob: any) => {
          resolve(blob);
        })
      }
    });
  }

  getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas');
    var context: any = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  onZoomIn(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.cropper.zoom(0.1);
  }
  onZoomOut(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.cropper.zoom(-0.1);
  }
  onZoom(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.cropper.zoomTo(1);
  }
  onRotateLeft(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.cropper.rotate(45);
  }
  onRotateRight(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.cropper.rotate(-45);
  }

  onAcept(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ disableButton: true }, async () => {
      const { onAcept, files } = this.props;
      const { currentImage } = this.state;

      const croppedData = await this.getCroppedData();
      const croppedFile = { ...files[currentImage], content: croppedData };

      if (currentImage + 1 === files.length) {
        this.setState({ currentImage: 0 }, () => {
          onAcept(croppedFile, true);
        });
      } else {
        this.setState((s: State) => ({ currentImage: s.currentImage + 1 }));
        onAcept(croppedFile, false);
      }

      this.setState({ disableButton: false })
    })
  }

  onCancel(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const { onCancel } = this.props;
    this.setState({ currentImage: 0 }, () => {
      onCancel();
    });
  }

  render() {
    const {
      show,
      onCancel,
      files,
      croperjsOptions,
      width,
      round = false,
    } = this.props;
    const { currentImage, disableButton } = this.state;
    const isLast = ((currentImage + 1) === files.length);

    if (round) {
      croperjsOptions.aspectRatio = 1;
    }

    let content = "";
    if (files.length > 0) {
      if (files[currentImage].content) {
        content = files[currentImage].content;
      } else if(files[currentImage].url) {
        content = files[currentImage].url;
      }
    }

    return (
      <Dialog
        header={`Ajusta la imagen - Imagen ${currentImage + 1} de ${files.length}`}
        visible={show}
        style={{ width: `${width}px` }}
        onHide={onCancel}>
        <span className='p-buttonset'>
          <Button icon='pi pi-reply' onClick={this.onRotateLeft} />
          <Button
            icon='pi pi-reply'
            onClick={this.onRotateRight}
            style={{ transform: 'rotate(180deg)', margin: '0 -1px' }}
          />
          <Button icon='pi pi-search-plus' onClick={this.onZoomIn} />
          <Button icon='pi pi-search-minus' onClick={this.onZoomOut} />
          <Button icon='pi pi-search' onClick={this.onZoom} />
        </span>
        {files.length > 0 &&
          <Cropper
            className={`${round ? 'crop-round' : ''}`}
            src={content}
            style={{ height: 400, width: '100%' }}
            onInitialized={this.onCropperInit.bind(this)}
            {...croperjsOptions}
          />
        }
        <div className='crop-dialog-footer'>
          <Button
            label='Cancelar'
            icon='pi pi-times'
            onClick={this.onCancel}
            className='p-button-text p-button-danger'
          />
          <Button
            disabled={disableButton}
            label={isLast ? 'Aceptar' : 'Siguiente'}
            icon='pi pi-check'
            onClick={this.onAcept}
            autoFocus
          />
        </div>
      </Dialog>
    );
  }
}
