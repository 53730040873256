import React from "react";
import "./menu.css";
import { Card } from "../../../models/card";

import { ProfessionalMenu } from "./professionalMenu";
import { MasterFideliumMenu } from "./masterFideliumMenu";
import { SubFideliumMenu } from "./subFideliumMenu";
import { MasterCorporateMenu } from "./masterCorporateMenu";
import { SubCorporateMenu } from "./subCorporateMenu";

interface CardEditorMenuProps {
  onChange: any;
  card: Card | null;
  isAdmin: boolean;
  isUser: boolean;
}

const activateStyle = {
  fontWeight: "bold",
  color: "rgba(4, 120, 87) !important",
};

export const CardEditorMenu = ({
  card,
  onChange,
  isAdmin,
  isUser,
}: CardEditorMenuProps) => {
  if (!card) return null;

  switch (card.Type) {
    case "card:professional":
      return <ProfessionalMenu card={card} onChange={onChange} />;
    case "master:fidelium":
      return (
        <MasterFideliumMenu card={card} onChange={onChange} isAdmin={isAdmin} />
      );
    case "master:corporate":
      return (
        <MasterCorporateMenu
          card={card}
          onChange={onChange}
          isAdmin={isAdmin}
        />
      );
    case "sub:fidelium":
      return (
        <SubFideliumMenu
          card={card}
          onChange={onChange}
          isAdmin={isAdmin}
          isUser={isUser}
        />
      );
    case "sub:corporate":
      return (
        <SubCorporateMenu
          card={card}
          onChange={onChange}
          isAdmin={isAdmin}
          isUser={isUser}
        />
      );
    default:
      return null;
  }
};
