import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentSectionAction } from "../../../store/application";

import "./section.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ImageUploaderField } from "../../../components/inputs/ImageUploaderField";
import { EmailField, TextArea, TextField } from "../../../components/inputs";
import { Card } from "../../../models/card";
import { useTranslation } from "react-i18next";

interface PerfilSectionProps {
  card: Card;
  name: string;
}

export const PerfilSection = ({ name, card }: PerfilSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleOnClick = () => {
    dispatch(setCurrentSectionAction("config"));
  };

  return (
    <div className="p-section-container">
      <div className="p-field p-grid">
        {/* <label htmlFor={name} className="p-col-fixed" style={{width:'120px'}}>{label}</label> */}
        <div className="p-col">
          {t("editor.profile-info1")}{" "}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span onClick={handleOnClick} className="p-section-link">
            {t("editor.profile-info2")}
          </span>
          <p style={{ textAlign: "left" }}>{t("editor.profile-info3")}</p>
        </div>
      </div>
      <Accordion multiple activeIndex={0}>
        <AccordionTab header={t("editor.contact-data")}>
          <ImageUploaderField
            values={card}
            label={t("editor.user-image")}
            id={`${name}.ProfileData.UserImage`}
            name={`${name}.ProfileData.UserImage`}
            style={{ width: 400 }}
            round={true}
            className={""}
            placeholder={card.Contact.UserImage}
          />
          <TextField
            label={t("editor.name")}
            name={`${name}.ProfileData.FirstName`}
            values={card}
            placeholder={card.Contact.FirstName || ""}
          />
          <TextField
            label={t("editor.lastname")}
            name={`${name}.ProfileData.LastName`}
            values={card}
            placeholder={card.Contact.LastName || ""}
          />
          <TextField
            label={t("editor.company")}
            name={`${name}.ProfileData.Company`}
            values={card}
            placeholder={card.Contact.Company || ""}
          />
          <TextField
            label={t("editor.charge")}
            name={`${name}.ProfileData.Charge`}
            values={card}
            placeholder={card.Contact.Charge || ""}
          />
          <EmailField
            label={t("editor.email")}
            name={`${name}.ProfileData.Email`}
            values={card}
            placeholder={card.Contact.Email || ""}
          />
          <TextField
            label={t("editor.mobile")}
            name={`${name}.ProfileData.Phone`}
            values={card}
            placeholder={card.Contact.Phone || ""}
          />
          <TextField
            label={t("editor.company-phone")}
            name={`${name}.ProfileData.CorporatePhone`}
            values={card}
            placeholder={card.Contact.CorporatePhone || ""}
          />
          <TextField
            label={t("editor.web")}
            name={`${name}.ProfileData.WebUrl`}
            values={card}
            placeholder={card.Contact.WebUrl || ""}
          />
          <TextArea
            label={t("editor.address")}
            name={`${name}.ProfileData.Address`}
            values={card}
            placeholder={card.Contact.Address || ""}
          />
        </AccordionTab>
        <AccordionTab header={t("editor.social-networks")}>
          <p style={{ textAlign: "left" }}>{t("editor.info4")}</p>
          <TextField
            label="Facebook"
            name={`${name}.ProfileData.Social.Facebook`}
            values={card}
            placeholder={card.Contact.Social.Facebook || ""}
            help="Introduce tu usuario de Facebook o la url a la página de tu perfil, incluyendo https:// al principio."
          />
          <TextField
            label="LinkedIn"
            name={`${name}.ProfileData.Social.Linkedin`}
            values={card}
            placeholder={card.Contact.Social.Linkedin || ""}
            help="Introduce la url a tu perfil de Linkedin, incluyendo https:// al principio."
          />
          <TextField
            label="Twitter"
            name={`${name}.ProfileData.Social.Twitter`}
            values={card}
            placeholder={card.Contact.Social.Twitter || ""}
            help="Introduce tu usuario de Twitter, que comienza con @ o la url a la página de tu perfil, incluyendo https:// al principio."
          />
          <TextField
            label="Instagram"
            name={`${name}.ProfileData.Social.Instagram`}
            values={card}
            placeholder={card.Contact.Social.Instagram || ""}
            help="Introduce tu usuario de Instagram o la url a la página de tu perfil, incluyendo https:// al principio."
          />
          <TextField
            label="Telegram"
            name={`${name}.ProfileData.Social.Telegram`}
            values={card}
            placeholder={card.Contact.Social.Telegram || ""}
            help="Introduce tu usuario de Telegram, que comienza con @"
          />
          <TextField
            label="Youtube"
            name={`${name}.ProfileData.Social.Youtube`}
            values={card}
            placeholder={card.Contact.Social.Youtube || ""}
            help="Introduce la url a tu canal de Youtube, incluyendo https:// al principio."
          />
          <TextField
            label="TikTok"
            name={`${name}.ProfileData.Social.Tiktok`}
            values={card}
            placeholder={card.Contact.Social.Tiktok || ""}
            help="Introduce tu usuario de TikTok, que comienza con @"
          />
          <TextField
            label="Reddit"
            name={`${name}.ProfileData.Social.Reddit`}
            values={card}
            placeholder={card.Contact.Social.Reddit || ""}
            help="Introduce tu usuario de Reddit."
          />
        </AccordionTab>
      </Accordion>
    </div>
  );
};
