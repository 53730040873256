import React from "react";
import { Chips } from "primereact/chips";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { getIn, setIn } from "../../utils";
import { useTranslation } from "react-i18next";
import { Tooltip } from "primereact/tooltip";

export const TagsField = ({ label, name, help, values }: any) => {
  const dispatch = useDispatch();
  const tags = getIn(values, name, []);
  const { t } = useTranslation();

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <>
      <Tooltip target=".tags-field">{help}</Tooltip>
      <div className="p-field p-grid">
        <label
          htmlFor="tags"
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {t("editor.tags")}
        </label>
        <div className="p-col">
          <Chips
            id="tags"
            className="tags-field"
            name="Search.Tags"
            separator=","
            style={{ width: 400 }}
            value={tags}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="p-field">
        <small id="tags-help" className="p-d-block">
          {help}
        </small>
      </div>
    </>
  );
};
