import React, { useEffect, useRef, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

import "./ImageGallery.css";
import { GalleryUploader } from "../uploaders/GalleryUploader";
import { useDispatch } from "react-redux";
import { arrayHelpers, getIn, setIn } from "../../utils";
import { updateCurrentCardAction } from "../../store/application";
import {
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { InputPopup } from "../dialogs/InputPopup";
import LoopIcon from "./LoopIcon";
import { UploaderPopup } from "../dialogs/UploaderPopup";
import { Tooltip } from "primereact/tooltip";

const ImageItem = ({ item }: any) => {
  const { Url, Type, Description } = item;
  return (
    <img
      draggable={false}
      src={Url}
      alt="slide"
      style={{ width: 180, borderRadius: 4 }}
    />
  );
};

const VideoItem = ({ item }: any) => {
  const { Url, Type, Description } = item;
  return (
    <video height={150} style={{ width: 180, borderRadius: 4 }} controls={true}>
      <source src={Url} type={Type} />
    </video>
  );
};

const SortableItem = SortableElement(
  ({ gkkey, value, onDeleteImage, onChangeValue, t, showLink, showFullScreen }: any) => {
    const {
      Type = "image",
      Link = "",
      AllowFullScreen,
      Loop,
      Autoplay,
      CoverUrl,
    } = value;
    const op = useRef<any>(null);
    const opUploader = useRef<any>(null);

    const header = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 150,
          justifyContent: "center",
          backgroundColor: "#000",
        }}
      >
        {Type.startsWith("image") && <ImageItem item={value} />}
        {Type.startsWith("video") && <VideoItem item={value} />}
        {Type.startsWith("audio") && <VideoItem item={value} />}
      </div>
    );

    const footer = (
      <div className="flex justify-between items-center">
        <Tooltip target="#drag-button" position={"top"}>
          {t("editor.drag-button")}
        </Tooltip>
        <Tooltip target=".link-button" position={"top"}>
          {t("editor.link-button")}
        </Tooltip>
        <Tooltip target=".allow-full-screen-button" position={"top"}>
          {t("editor.fullscreen-button")}
        </Tooltip>
        <Tooltip target=".delete-button" position={"top"}>
          {t("editor.delete-button")}
        </Tooltip>
        <Tooltip target=".autoplay-button" position={"top"}>
          {t("editor.autoplay-button")}
        </Tooltip>
        <Tooltip target=".loop-button" position={"top"}>
          {t("editor.loop-button")}
        </Tooltip>
        <Tooltip target=".cover-button" position={"top"}>
          {t("editor.cover-button")}
        </Tooltip>

        <i
          id="drag-button"
          className="fal fa-arrows lg"
          style={{ width: 38 }}
        />

        {Type.startsWith("image") && (
          <>
            { showLink && <Button
              label=""
              className={`gallery-footer-button link-button ${
                Link ? "p-button-info" : "p-button-secondary"
              } p-button-text`}
              style={{
                color: Link ? "" : "#666",
              }}
              onClick={(e) => {
                op.current.toggle(e);
              }}
            >
              <i
                className={`fal fa-link  ${
                  Link !== "" ? "text-2xl" : "text-lg"
                }`}
                style={{ fontWeight: Link !== "" ? "bold" : "normal" }}
              />
            </Button> }
            { showFullScreen && <Button
              label=""
              className={`allow-full-screen-button gallery-footer-button ${
                AllowFullScreen ? "p-button-info" : "p-button-secondary"
              } p-button-text`}
              style={{
                color: AllowFullScreen ? "" : "#666",
              }}
              onClick={(e) => {
                const item = {
                  ...value,
                  AllowFullScreen: !value.AllowFullScreen,
                };
                onChangeValue(gkkey, item);
              }}
            >
              <i
                className={`fal fa-expand  ${
                  AllowFullScreen ? "text-2xl" : "text-lg"
                }`}
                style={{ fontWeight: AllowFullScreen ? "bold" : "normal" }}
              />
            </Button> }
          </>
        )}

        {!Type.startsWith("image") && (
          <>
          { showFullScreen &&
            <Button
              label=""
              className={`autoplay-button gallery-footer-button ${
                Autoplay ? "p-button-info" : "p-button-secondary"
              } p-button-text`}
              style={{
                color: Autoplay ? "" : "#666",
              }}
              onClick={(e) => {
                const item = {
                  ...value,
                  Autoplay: !value.Autoplay,
                };
                onChangeValue(gkkey, item);
              }}
            >
              <i
                className={`fal fa-play  ${Autoplay ? "text-2xl" : "text-lg"}`}
                style={{ fontWeight: Autoplay ? "bold" : "normal" }}
              />
            </Button>
          }
          { showFullScreen &&
            <Button
              label=""
              className={`loop-button gallery-footer-button ${
                Loop ? "p-button-info" : "p-button-secondary"
              } p-button-text`}
              style={{
                color: Loop ? "" : "#666",
              }}
              onClick={(e) => {
                const item = {
                  ...value,
                  Loop: !value.Loop,
                };
                onChangeValue(gkkey, item);
              }}
            >
              <LoopIcon
                color={Loop ? "#0288D1" : "#666"}
                width={Loop ? 3 : 1}
              />
            </Button> }
          { showFullScreen && <Button
              label=""
              className={`cover-button gallery-footer-button ${
                CoverUrl !== "" ? "p-button-info" : "p-button-secondary"
              } p-button-text`}
              style={{
                color: CoverUrl !== "" ? "" : "#666",
              }}
              onClick={(e) => {
                opUploader.current.toggle(e);
              }}
            >
              <i
                className={`fal fa-image  ${
                  CoverUrl !== "" ? "text-2xl" : "text-lg"
                }`}
                style={{ fontWeight: CoverUrl !== "" ? "bold" : "normal" }}
              />
            </Button> }
          </>
        )}

        <Button
          label=""
          className="delete-button gallery-footer-button p-button-danger p-button-text"
          onClick={() => {
            onDeleteImage(gkkey);
          }}
        >
          <i className="fal fa-trash lg" />
        </Button>
      </div>
    );

    return (
      <div
        key={gkkey}
        className="gk-gallery-item-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Card className="gk-gallery-item" header={header} footer={footer} />
        <InputPopup
          op={op}
          label={t("editor.image-link")}
          initialValue={Link || ""}
          onChange={(link) => {
            onChangeValue(gkkey, { ...value, Link: link });
          }}
        />{" "}
        <UploaderPopup
          op={opUploader}
          label={t("editor.video-cover")}
          initialValue={CoverUrl}
          onChange={(content) => {
            onChangeValue(gkkey, { ...value, CoverUrl: content });
          }}
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, onAddImage, onDeleteImage, crop, onChangeValue, t, showLink, showFullScreen }: any) => {
    return (
      <ul className="gk-gallery-list-wrapper">
        {items.map((value: any, index: number) => (
          <SortableItem
            key={value}
            name={`Header.Slider.Slides[${index}].Url`}
            gkkey={index}
            onDeleteImage={onDeleteImage}
            index={index}
            value={value}
            t={t}
            onChangeValue={onChangeValue}
            showLink={showLink}
            showFullScreen={showFullScreen}
          />
        ))}
        <GalleryUploader crop={crop} onAddImage={onAddImage} />
      </ul>
    );
  }
);

interface ImageGalleryProps extends WithTranslation {
  onChange: any;
  values: any;
  crop: boolean;
  showLink: boolean;
  showFullScreen: boolean;
}

interface State {
  items: any;
}

class _ImageGallery extends React.Component<ImageGalleryProps, State> {
  constructor(props: ImageGalleryProps) {
    super(props);

    this.state = {
      items: [...props.values],
    };
    this.onAddImage = this.onAddImage.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onSortEnd = ({ newIndex, oldIndex }: any) => {
    const items = arrayHelpers.move(this.state.items, oldIndex, newIndex);
    this.setState({ items });
    this.props.onChange(items);
  };

  onChangeValue(index: number, value: any) {
    const items = [...this.state.items];
    items[index] = value;
    this.setState({ items });
    this.props.onChange(items);
  }

  onAddImage(file: any) {
    let type = "";

    if (!file.type) {
      type = getFileType(file.content);
    } else {
      type = file.type;
    }
    const items = [
      ...this.state.items,
      { Url: file.content, Type: type, Original: file.name || file.path },
    ];
    this.setState({ items });
    this.props.onChange(items);
  }

  onDeleteImage(index: number) {
    const items = arrayHelpers.remove(this.state.items, index);
    this.setState({ items });
    this.props.onChange(items);
  }

  render() {
    const { items } = this.state;
    const { crop, t , showLink = false, showFullScreen = false} = this.props;
    return (
      <div className="gk-gallery">
        <SortableList
          helperClass="gk-dragging"
          distance={10}
          axis={"x"}
          items={items}
          onSortEnd={this.onSortEnd}
          onAddImage={this.onAddImage}
          onDeleteImage={this.onDeleteImage}
          crop={crop}
          t={t}
          onChangeValue={this.onChangeValue}
          showLink={showLink}
          showFullScreen={showFullScreen}
        />
      </div>
    );
  }
}

export const ImageGallery = withTranslation()(_ImageGallery);

export const ImageGalleryField = (props: any) => {
  let { name, values, crop, showLink = false, showFullScreen = false  } = props;
  const dispatch = useDispatch();
  const items = getIn(values, name, []);

  const handleOnChange = (value: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <div
      className="p-field p-grid"
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <ImageGallery crop={crop} onChange={handleOnChange} values={items} showLink={showLink} showFullScreen={showFullScreen}/>
    </div>
  );
};

function getFileType(content: string): string {
  if (!content.startsWith("data:")) {
    return "";
  }

  const parts = content.split("/")[0].replace("data:", "");
  return parts;
}
