import React, { useEffect, useState } from "react";
import Joyride, { StoreHelpers } from "react-joyride";
import { gkb } from "../service/gkb";
import { ContentComponent, Locale } from "./components";
import { useTranslation } from "react-i18next";

const BaseStep = {
  locale: Locale,
  placement: "auto",
  isFixed: true,
};

export const TutorComponent = ({ tutorialID, show, onClose }) => {
  const [tutorial, setTutorial] = useState<any>(null);
  const [steps, setSteps] = useState<any>([]);
  const [run, setRun] = useState(false);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  let helpers: StoreHelpers;

  const handleLanguageChanged = (event: any) => {
    setLanguage(event.detail);
  };

  useEffect(() => {
    window.addEventListener(
      "General.LanguageChanged",
      handleLanguageChanged,
      false
    );

    gkb
      .GetApiResource("tutorials", tutorialID)
      .then((response) => {
        setTutorial(response);
      })
      .catch()
      .finally(() => {});

    return () => {
      window.removeEventListener(
        "General.LanguageChanged",
        handleLanguageChanged
      );
    };
  }, []);

  useEffect(() => {
    setRun(show);
  }, [show]);

  useEffect(() => {
    const steps = [];
    if (!tutorial) {
      return;
    }

    for (let i = 0; i < tutorial.Steps.length; i++) {
      steps.push({
        ...BaseStep,
        content: (
          <ContentComponent content={tutorial.Steps[i].Content[language]} />
        ),
        target: tutorial.Steps[i].Target,
        placement: tutorial.Steps[i].Target === "body" ? "center" : "auto",
      });
    }

    setSteps(steps);
  }, [tutorial, language]);

  const getHelpers = (event: StoreHelpers) => {
    helpers = event;
  };

  const handleCallback = ({ action, index, status, type }) => {
    if (action === "close" || action === "reset") {
      onClose();
    }
  };

  if (steps.length === 0 || show === false) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      run={show}
      continuous={true}
      disableScrolling={true}
      showProgress={false}
      showSkipButton={true}
      getHelpers={getHelpers}
      callback={handleCallback}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: "#047857",
        },
      }}
    />
  );
};
