import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
  show: boolean;
  title: string;
  children?: any;
  onOk?: any;
  onCancel?: any;
}

export const ConfirmDialog = ({
  show,
  title,
  children,
  onOk = null,
  onCancel = null,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      header={title}
      visible={show}
      style={{ width: "200" }}
      closable={false}
      onHide={() => {
        onCancel && onCancel();
      }}
    >
      {children}
      <div className={`flex justify-${(onCancel && onOk)?'between':'end'} mt-10`}>
        {onCancel && <Button
          label={t("editor.cancel")}
          className={"p-button-secondary"}
          style={{
            color: "",
          }}
          onClick={() => {
            onCancel && onCancel();
          }}
        /> }
        { onOk && <Button
          label={t("editor.acept")}
          className={`p-button-primary`}
          style={{
            color: "",
          }}
          onClick={() => {
            onOk && onOk();
          }}
        /> }
      </div>
    </Dialog>
  );
};
