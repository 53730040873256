import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TinyMCEEditorField } from "../../../components/inputs/TinyMCEEditor";
import { CardFactory } from "../../../models/card/CardFactory";
import { updateCurrentCardAction } from "../../../store/application";
import { getIn, setIn } from "../../../utils";

import "./section.scss";

export const HtmlSection = ({ card, name }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const data = getIn(card, `${name}.HtmlData`);
    if (!data) {
      const nc = setIn(card, `${name}.HtmlData`, CardFactory.newHtmlData());
      dispatch(updateCurrentCardAction(nc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return (
    <div className="p-section-container">
      <TinyMCEEditorField
        label={""}
        name={`${name}.HtmlData.Text`}
        singleLine={false}
        values={card}
      />
    </div>
  );
};
