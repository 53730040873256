import React from "react";

import "./section.scss";
import { ImageGalleryField } from "../../../components/imageGallery/ImageGallery";

export const GallerySection = ({ card, name }: any) => {
  return (
    <div className="p-section-container">
      <div className="p-field p-grid">
        <div className="p-col">
          <ImageGalleryField
            crop={false}
            name={`${name}.GalleryData`}
            values={card}
            showLink={true}
            showFullScreen={true}
          />
        </div>
      </div>
    </div>
  );
};
