import React, { createRef } from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card as CardDTO, FooterData } from "../../models/card";

import { ColorField } from "../../components/inputs/ColorField";

import "./styles.css";
import "react-upload-gallery/dist/style.css"; // or scss
import { CheckboxField, DropdownField } from "../../components/inputs";
import { DirectAccess } from "../data/directAccess";
import { WithTranslation, withTranslation } from "react-i18next";

interface ConfigValues {
  Footer: FooterData | null;
}

interface FooterEditorProps extends WithTranslation {
  onChange: any;
  card: CardDTO | null;
  isAdmin: boolean;
}

export class _FooterEditor extends React.Component<FooterEditorProps, any> {
  private readonly initialValues: ConfigValues;
  private lastValues: ConfigValues;
  private formRef: any;

  constructor(props: FooterEditorProps) {
    super(props);

    const { card } = this.props;
    this.initialValues = {
      Footer: card ? { ...card.Footer } : null,
    };

    this.formRef = createRef();
    this.lastValues = { ...this.initialValues };
  }

  render() {
    const { card, t, isAdmin = false } = this.props;

    return (
      <Card>
        <Accordion multiple activeIndex={0}>
          <AccordionTab header={t("editor.general")}>
            <ColorField
              values={card}
              label={t("editor.background-color")}
              name="Footer.BackgroundColor"
              advanced={isAdmin}
            />
            <CheckboxField label={t("editor.footer-flat-icons")} name="Footer.FlatIcons" id="Footer.FlatIcons" values={card} />
          </AccordionTab>
          <AccordionTab header={t("editor.shortcuts")}>
            {card.Type === "master:corporate" && isAdmin &&
              <CheckboxField label={t("editor.block-footer")} name="Footer.Locked" values={card} />
            }
            <DropdownField
              values={card}
              items={DirectAccess}
              label={t("editor.shortcut") + " 1"}
              name="Footer.QuickAccess[0]"
            />
            <DropdownField
              values={card}
              items={DirectAccess}
              label={t("editor.shortcut") + " 2"}
              name="Footer.QuickAccess[1]"
            />
            <DropdownField
              values={card}
              items={DirectAccess}
              label={t("editor.shortcut") + " 3"}
              name="Footer.QuickAccess[2]"
            />
            <DropdownField
              values={card}
              items={DirectAccess}
              label={t("editor.shortcut") + " 4"}
              name="Footer.QuickAccess[3]"
            />
          </AccordionTab>
        </Accordion>
      </Card>
    );
  }
}

export const FooterEditor = withTranslation()(_FooterEditor);
