import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { OverlayPanel } from "../primereact/components/overlaypanel/OverlayPanel";
export const InputPopup = ({ op, label, initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <OverlayPanel ref={op}>
      <div className="flex flex-col">
        {label && (
          <label htmlFor={"input-popup"} className="p-col-fixed w-full">
            {label}
          </label>
        )}
        <div className="flex">
          <InputText
            id={"input-popup"}
            name={"input-popup"}
            style={{ width: 400 }}
            tooltip={""}
            tooltipOptions={{ position: "right" }}
            value={value}
            disabled={false}
            placeholder={"http..."}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
          />
          <Button
            label=""
            className="p-button-primary p-button-text"
            onClick={() => {
              onChange(value);
              op.current.hide();
            }}
          >
            <i className="fal fa-check text-xl" />
          </Button>
          <Button
            label=""
            className="p-button-danger p-button-text"
            onClick={(e) => {
              onChange("");
              op.current.hide();
            }}
          >
            <i className="fal fa-trash text-xl" />
          </Button>
        </div>
      </div>
    </OverlayPanel>
  );
};
