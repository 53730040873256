import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import "./i18n";
import { CardEditor } from "./pages/cardEditor/cardEditor";
import { useTranslation } from "react-i18next";
import { TemplateEditor } from "./pages/templateEditor/templateEditor";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "./store/application";

function App() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const dispatch = useDispatch();

  const handleLanguageChanged = (event: any) => {
    setLanguage(event.detail);
  };

  useEffect(() => {
    const evt = new CustomEvent("General.SetSpinner", { detail: true });
    window.dispatchEvent(evt);

    window.addEventListener(
      "General.LanguageChanged",
      handleLanguageChanged,
      false
    );
    return () => {
      dispatch(updateCurrentCardAction(null));
      window.removeEventListener(
        "General.LanguageChanged",
        handleLanguageChanged
      );
    };
  }, []);

  return (
    <div className={""} style={{ backgroundColor: "#f5f5f5" }}>
      <Router>
        <Switch>
          <Route
            path="/editor/card/:id"
            children={<CardEditor language={language} />}
          />
          <Route
            path="/editor/template/:id"
            children={<TemplateEditor language={language} />}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
