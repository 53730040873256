import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CheckboxField, TextField } from "../../../components/inputs";
import { CardFactory } from "../../../models/card/CardFactory";
import { updateCurrentCardAction } from "../../../store/application";
import { getIn, setIn } from "../../../utils";
import { useTranslation } from "react-i18next";

import "./section.scss";

export const FormSection = ({ card, name }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const data = getIn(card, `${name}.FormData`);
    if (!data) {
      const nc = setIn(card, `${name}.FormData`, CardFactory.newFormData());
      dispatch(updateCurrentCardAction(nc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return (
    <div className="p-section-container">
      <TextField
        label={t("editor.text-for-user")}
        name={`${name}.FormData.Text`}
        values={card}
      />
      <CheckboxField
        label={t("editor.email")}
        name={`${name}.FormData.Email`}
        values={card}
        disabled={true}
      />
      <CheckboxField
        label={t("editor.name")}
        name={`${name}.FormData.Name`}
        values={card}
      />
      <CheckboxField
        label={t("editor.last-name")}
        name={`${name}.FormData.LastName`}
        values={card}
      />
      <CheckboxField
        label={t("editor.address")}
        name={`${name}.FormData.Address`}
        values={card}
      />
      <CheckboxField
        label={t("editor.web")}
        name={`${name}.FormData.Web`}
        values={card}
      />
      <CheckboxField
        label={t("editor.company")}
        name={`${name}.FormData.Corporate`}
        values={card}
      />
      <CheckboxField
        label={t("editor.company-phone")}
        name={`${name}.FormData.Phone`}
        values={card}
      />
      <CheckboxField
        label={t("editor.mobile")}
        name={`${name}.FormData.Mobile`}
        values={card}
      />
      <CheckboxField
        label={t("editor.subject")}
        name={`${name}.FormData.Subject`}
        values={card}
      />
      <CheckboxField
        label={t("editor.message")}
        name={`${name}.FormData.Message`}
        values={card}
        disabled={true}
      />
    </div>
  );
};
