import { Editor } from 'primereact/editor';
import React, { useEffect, useState } from 'react';
import {  Field } from 'formik';

const modules = {
    keyboard: {
      bindings: {
      enter: {
        key: 13,
        handler: function() {
          return false;
        }
      }
    }
  }
};

const RichLineEditor = ({value, onChange, name}:any) =>  {
    const [text, setText] = useState('');
    useEffect(()=>{
        setText(value);
    }, [value]);
    
    const handleOnChange = (e: any) => {
        const value = (e?.htmlValue || '').replace(/(\r\n|\n|\r)/gm, ' ');
        onChange({ target: { value: value, name } });
        setText(value);    
    }

    return <Editor value={text} onTextChange={handleOnChange} modules={modules}/>
}

export const RichLineField = ({ errors, touched, label, name, help}:any) => {
    return (
        <>
        <div className='p-field p-grid p-rich-line-field'>
        <label htmlFor={name} className="p-col-fixed" style={{width:'90px'}}>{label}</label>
        <div className="p-col">
          <Field id={name} name={name}  as={RichLineEditor} style={{width: 400}} tooltip={help} tooltipOptions={{position: 'right'}} /> 
          { errors[name] && touched[name] && <small id={`${name}-error-help`} className="p-invalid p-d-block" style={{ textAlign: 'left'}}>{errors[name]}</small>}
        </div>
        </div>
        </>
    )
}
