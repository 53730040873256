import React, { createRef } from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Card as CardDTO, SectionData } from "../../models/card";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import { confirmPopup } from "primereact/confirmpopup";

import "./styles.css";
import "react-upload-gallery/dist/style.css"; // or scss
import { CheckboxField, DropdownField } from "../../components/inputs";
import { Sections } from "../data/sections";
import { ColorField } from "../../components/inputs/ColorField";
import { PerfilSection } from "./sections/perfilSection";
import { FormSection } from "./sections/formSection";
import { HtmlSection } from "./sections/htmlSection";
import { QuillEditorField } from "../../components/inputs/QuillEditor";
import { DownloadsSection } from "./sections/downloadsSection";
import { GallerySection } from "./sections/gallerySection";
import { setCurrentSectionAction } from "../../store/application";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import { LinkSection } from "./sections/linkSection";

interface ConfigValues {
  Section: SectionData | null;
}

interface SectionEditorProps extends WithTranslation {
  setCurrentSection?: any;
  onChange: any;
  card: CardDTO | null;
  section: string;
  visible: boolean;
  isAdmin: boolean;
}

export class _SectionEditor extends React.Component<SectionEditorProps, any> {
  private readonly initialValues: ConfigValues;
  private lastValues: ConfigValues;
  private formRef: any;

  constructor(props: SectionEditorProps) {
    super(props);

    const { card, section } = this.props;
    this.initialValues = {
      Section: card ? { ...card.Sections[parseInt(section)] } : null,
    };

    this.formRef = createRef();
    this.lastValues = { ...this.initialValues };
    this.handleDeleteSection = this.handleDeleteSection.bind(this);
  }

  findSectionById(id: string) {
    const { card } = this.props;

    if (card) {
      for (let i = 0; i < card?.Sections.length; i++) {
        if (card.Sections[i].SectionID === id) {
          return i;
        }
      }
    }

    return -1;
  }

  handleDeleteSection(event: any) {
    const { card, section, onChange, setCurrentSection, t } = this.props;

    confirmPopup({
      message: t("editor.delete-warning"),
      icon: "pi pi-info-circle",
      acceptLabel: t("editor.delete-confirm"),
      acceptClassName: "p-button-danger",
      target: event.currentTarget,
      accept: () => {
        const Sections = card?.Sections?.filter(
          (s: SectionData) => s.SectionID !== section
        );
        const nc = { ...card, Sections };
        onChange(nc);
        setCurrentSection("config");
      },
      reject: () => {},
    });
  }

  render() {
    const { isAdmin, card, section, visible, t } = this.props;
    if (!visible) {
      return null;
    }

    const sectionIndex = this.findSectionById(section);

    return (
      <Card>
        <Accordion multiple activeIndex={[0, 1]}>
          <AccordionTab header={t("editor.button")}>
            {/*{isAdmin && (*/}
            {/*  <CheckboxField*/}
            {/*    label={t("editor.readonly")}*/}
            {/*    name={`Sections[${sectionIndex}].ReadOnly`}*/}
            {/*    values={card}*/}
            {/*  />*/}
            {/*)}*/}
            {card.Type === "master:corporate" && isAdmin &&
              <CheckboxField label={t("editor.block-section")} name={`Sections[${sectionIndex}].ReadOnly`} values={card} />
            }

            <CheckboxField
              label={t("editor.hidden")}
              name={`Sections[${sectionIndex}].Hidden`}
              values={card}
            />
            <ColorField
              label={t("editor.background-color")}
              name={`Sections[${sectionIndex}].Button.BackgroundColor`}
              values={card}
              advanced={isAdmin}
            />
            <QuillEditorField
              name={`Sections[${sectionIndex}].Button.Text`}
              singleLine={true}
              values={card}
              backgroundColor={
                card?.Sections[sectionIndex].Button.BackgroundColor
              }
            />
          </AccordionTab>
          <AccordionTab header={t("editor.content")}>
            <DropdownField
              values={card}
              items={Sections}
              label={t("editor.content-type")}
              name={`Sections[${sectionIndex}].Type`}
            />
            <div className="p-section-separator" />
            {card?.Sections[sectionIndex].Type === 10 && (
              <PerfilSection card={card} name={`Sections[${sectionIndex}]`} />
            )}
            {card?.Sections[sectionIndex].Type === 1 && (
              <FormSection card={card} name={`Sections[${sectionIndex}]`} />
            )}
            {card?.Sections[sectionIndex].Type === 3 && (
              <HtmlSection card={card} name={`Sections[${sectionIndex}]`} />
            )}
            {card?.Sections[sectionIndex].Type === 4 && (
              <LinkSection card={card} name={`Sections[${sectionIndex}]`} />
            )}
            {card?.Sections[sectionIndex].Type === 2 && (
              <GallerySection card={card} name={`Sections[${sectionIndex}]`} />
            )}
            {card?.Sections[sectionIndex].Type === 7 && (
              <DownloadsSection
                card={card}
                name={`Sections[${sectionIndex}]`}
              />
            )}
            <Button
              label={t("editor.delete-section")}
              className="p-button-text p-button-danger"
              style={{ marginBottom: 20 }}
              onClick={this.handleDeleteSection}
            />
          </AccordionTab>
        </Accordion>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentSection: (section: string) =>
    dispatch(setCurrentSectionAction(section)),
});

export const SectionEditor = compose(
  withTranslation(),
  connect(null, mapDispatchToProps)
)(_SectionEditor);
