import React, { useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Card as PrimeCard } from "primereact/card";
import "./menu.css";
import { Card, SectionData } from "../../../models/card";
import { arrayHelpers } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentSection,
  setCurrentSectionAction,
} from "../../../store/application";
import { StoreState } from "../../../store";
import { CardFactory } from "../../../models/card/CardFactory";
import { htmlToPlainText } from "../../../models/textversion";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";
import { MenuItem } from "./menuItems";

interface CardEditorMenuProps {
  onChange: any;
  card: Card | null;
  isAdmin: boolean;
  isUser: boolean;
}

const activateStyle = {
  fontWeight: "bold",
  color: "rgba(4, 120, 87) !important",
};

export const SubFideliumMenu = ({
  card,
  onChange,
  isAdmin,
  isUser,
}: CardEditorMenuProps) => {
  const { t } = useTranslation();
  const { Type = "" } = card || { Type: "" };
  const [showMasterSectionDialog, setShowMasterSectionDialog] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");

  const currentSection = useSelector((state: StoreState) =>
    getCurrentSection(state.application)
  );
  const dispatch = useDispatch();
  const toast = useRef<any>(null);

  const handleChangeSelected = (eventKey: string) => {
    dispatch(setCurrentSectionAction(eventKey));
  };

  const handleChangeMasterSelected = (sectionID: string) => {
    let showWarning = true;
    if (sectionID.startsWith("section:")) {
      sectionID = sectionID.replace("section:", "");
    }

    setSelectedSection(sectionID);

    for (let i = 0; i < card.Sections.length; i++) {
      if (card.Sections[i].SectionID === sectionID) {
        showWarning = false;
      }
    }

    if (showWarning) {
      setShowMasterSectionDialog(true);
    } else {
      prepareMasterSectionForOverwrite(sectionID);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newSections = arrayHelpers.move(sections, oldIndex, newIndex);
    setSections(newSections);

    const nc = { ...card };
    nc.Sections = [
      ...newSections.map((s: any, i: number) => ({ ...s.data, Order: i + 1 })),
      ...card.Sections.filter((s: any) => s.MasterOverwrite),
    ];

    onChange(nc);
  };

  const maxPagesReached = () => {
    if (card?.Config?.MaxPagesNumber) {
      if (card?.Sections?.length >= card.Config?.MaxPagesNumber) {
        return true;
      }
    }

    return false;
  };

  const handleNewSection = () => {
    if (maxPagesReached()) {
      return;
    }

    const newSection = CardFactory.newSection();
    if (card?.Sections) {
      newSection.Order = card.Sections.length;
      const nc = {
        ...card,
        Sections: [...card.Sections, newSection],
      };

      onChange(nc);
      setTimeout(() => {
        handleChangeSelected(`section:${newSection.SectionID}`);
      }, 0);
    }
  };

  const prepareMasterSectionForOverwrite = (sectionID: string) => {
    for (let i = 0; i < card.Sections.length; i++) {
      if (card.Sections[i].SectionID === sectionID) {
        handleChangeSelected(`section:${sectionID}`);
        return;
      }
    }

    const nc = JSON.parse(JSON.stringify(card));
    for (let i = 0; i < card.Mastercard?.Sections?.length; i++) {
      if (card.Mastercard?.Sections[i].SectionID === sectionID) {
        nc.Sections.push({
          ...card.Mastercard?.Sections[i],
          ID: 0,
          MasterOverwrite: true,
          ReadOnly: true,
          //Hidden: false,
        });
      }
    }

    onChange(nc);
    handleChangeSelected(`section:${sectionID}`);
  };

  const items: any = [
    {
      label: "",
      items: [
        {
          className: "menu-configuration",
          label: t("editor.configuration"),
          style: currentSection === "config" ? activateStyle : {},
          command: () => {
            handleChangeSelected("config");
          },
        },
      ],
    },
  ];

  if (isUser) {
    items.push({
      className: "menu-header",
      label: t("editor.header"),
      style: currentSection === "header" ? activateStyle : {},
      command: () => {
        handleChangeSelected("header");
      },
    });
    items.push({
      className: "menu-footer",
      label: t("editor.footer"),
      style: currentSection === "footer" ? activateStyle : {},
      command: () => {
        handleChangeSelected("footer");
      },
    });
  }

  const [sections, setSections] = useState<any>([]);
  useEffect(() => {
    if (card?.Sections) {
      setSections(
        card.Sections.filter(
          (s: SectionData) => !s.MasterOverwrite && !s.ReadOnly
        ).map((s: SectionData) => ({
          index: s.SectionID,
          label: htmlToPlainText(s.Button.Text),
          data: s,
        }))
      );
    }
  }, [card]);

  const maxPages = maxPagesReached();
  const masterSections = card?.Mastercard?.Sections.map((s: SectionData) => {
    for (let i = 0; i < card?.Sections?.length; i++) {
      if (card?.Sections[i].SectionID === s.SectionID) {
        return card?.Sections[i];
      }
    }
    return s;
  });

  return (
    <div
      style={{
        minHeight: "calc(100vh - 300px)",
        borderRight: "border-right: 1px solid #ddd",
      }}
    >
      <ConfirmDialog
        show={showMasterSectionDialog}
        title={"Sección de plantilla"}
        onOk={() => {
          setShowMasterSectionDialog(false);
          prepareMasterSectionForOverwrite(selectedSection);
        }}
        onCancel={() => {
          setShowMasterSectionDialog(false);
        }}
      >
        <div className={"w-full text-center"}>
          <p>Esta sección se encuentra configurada en la plantilla.</p>
          <p>¿Estás seguro de que deseas modificarla para esta tarjeta?</p>
        </div>
      </ConfirmDialog>
      <Toast ref={toast} />
      <div className="menu-section-1">
        <Menu model={items} />
      </div>
      {isUser && (
        <>
          <h2 className="pmenu-h2">{t("editor.sections")}</h2>
          <SortableList
            items={sections}
            onSortEnd={onSortEnd}
            distance={10}
            lockAxis={"y"}
            currentSection={currentSection}
            card={card}
            handleChangeSelected={handleChangeSelected}
            onChange={onChange}
          />

          <Button
            disabled={maxPages}
            label={t("editor.new-section")}
            className={`p-button button-new-section p-button-raised`}
            style={{
              marginBottom: 20,
              color: maxPages ? "gray" : "",
              opacity: maxPages ? 0.2 : 1,
            }}
            onClick={handleNewSection}
          />
        </>
      )}
      {card?.Mastercard?.Sections?.length > 0 && isAdmin && (
        <>
          <h2 className="pmenu-h2">{t("editor.master-sections")}</h2>
          <ul>
            {masterSections.map((s: SectionData) => {
              const selected = `section:${s.SectionID}` === currentSection;
              return (
                <MenuItem
                  key={s.SectionID}
                  section={s}
                  selected={selected}
                  onChangeSelected={handleChangeMasterSelected}
                  isAdmin={isAdmin}
                  card={card}
                  onChange={onChange}
                  isMaster={true}
                />
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

const SortableItem = SortableElement(
  ({ value, handleChangeSelected, currentSection, card, onChange }: any) => {
    const selected = `section:${value.index}` === currentSection;
    return (
      <MenuItem
        section={value.data}
        selected={selected}
        onChangeSelected={handleChangeSelected}
        card={card}
        onChange={onChange}
      />
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleChangeSelected, currentSection, card, onChange }: any) => {
    return (
      <ul style={{ listStyle: "none", padding: 0, overflowY: "auto" }}>
        {items.map((value: any, index: number) => (
          <SortableItem
            handleChangeSelected={handleChangeSelected}
            key={`item-${index}`}
            index={index}
            value={value}
            currentSection={currentSection}
            card={card}
            onChange={onChange}
          />
        ))}
      </ul>
    );
  }
);
