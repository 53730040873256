import React from "react";
import { Card as PrimeCard } from "primereact/card";
import { confirmPopup } from "primereact/confirmpopup";
import { SectionData } from "../../../models/card";
import { useTranslation } from "react-i18next";

export const MenuItem = ({
  selected,
  section,
  onChangeSelected,
  card,
  onChange,
  isAdmin = true,
  isMaster = false,
  disabled = false
}) => {
  const { t } = useTranslation();

  const handleDeleteSection = (event: any) => {
    confirmPopup({
      message: t("editor.delete-warning"),
      icon: "pi pi-info-circle",
      acceptLabel: t("editor.delete-confirm"),
      acceptClassName: "p-button-danger",
      target: event.currentTarget,
      accept: () => {
        const Sections = card?.Sections?.filter(
          (s: SectionData) => s.SectionID !== section.SectionID
        );
        const nc = { ...card, Sections };
        onChange(nc);

        if (selected) {
          if (Sections.length > 0) {
            onChangeSelected("section:" + Sections[0].SectionID);
          } else {
            onChangeSelected("");
          }
        }
      },
      reject: () => {},
    });
  };

  return (
    <div className={"flex w-full items-center"}>
      {!isMaster && (
        <div
          className={"pl-2 cursor-pointer hover:opacity-50 flex flex-col"}
          onClick={handleDeleteSection}
        >
          <i className="fal fa-arrows-alt-v text-grey-800" />
        </div>
      )}
      <li
        style={{
          listStyle: "none",
          margin: 10,
          textAlign: "center",
          cursor: isAdmin ? "pointer" : "default",
          flex: 1,
          backgroundColor: "#f5f5f5",
        }}
        className={selected ? "section-selected" : ""}
        onClick={() => onChangeSelected(`section:${section.SectionID}`)}
      >
        <PrimeCard
          style={{
            opacity: isAdmin ? (section.Hidden ? 0.2 : 1) : 0.6,
            padding: "10px 0",
            background: section.Button.BackgroundColor,
            border: section.MasterOverwrite ? "2px dashed orange" : "none",
          }}
        >
          <div
            className="primer-card-inner"
            dangerouslySetInnerHTML={{ __html: section.Button.Text }}
          />
        </PrimeCard>
      </li>
      {(!isMaster || section.MasterOverwrite) && (
        <div
          className={"pr-2 cursor-pointer hover:opacity-50 mr-2"}
          onClick={handleDeleteSection}
        >
          <i className="fal fa-trash text-red-600" />
        </div>
      )}
    </div>
  );
};
