import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_RESOURCE_ERROR,
  LOAD_RESOURCE_REQUEST,
  LOAD_RESOURCE_SUCCESS,
  SAVE_CARD_ERROR,
  SAVE_CARD_REQUEST,
  SAVE_CARD_SUCCESS,
  SAVE_COMPANY_ERROR,
  SAVE_COMPANY_REQUEST,
  SAVE_COMPANY_SUCCESS,
} from "./actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { Card } from "../../models/card";
import { gkb, UploadCardImages } from "../../service/gkb";
import { Resource } from "../../models/resource/ResourceDTO";
import { Company } from "../../models/company/CompanyDTO";

async function loadResourceById(id: string) {
  return gkb.LoadResourceById(id);
}

async function updateCard(card: Card) {
  return await gkb.UpdateApiResource({
    name: "cards",
    id: card.ID,
    body: card,
  });
}

async function updateCompany(company: Company) {
  return await gkb.UpdateApiResource({
    name: "companies",
    id: company.ID,
    body: company,
  });
}

async function uploadImages(card: Card) {
  return UploadCardImages(card);
}

async function notify(message: string, type: string) {
  window.dispatchEvent(
    new CustomEvent("General.showNotification", { detail: { message, type } })
  );
}

function* loadResource(action: PayloadAction<string>) {
  try {
    let resource: Resource;
    resource = yield call<any>(loadResourceById, action.payload);
    yield put({ type: LOAD_RESOURCE_SUCCESS, payload: resource });
  } catch (e) {
    yield put({ type: LOAD_RESOURCE_ERROR, payload: e.message });
  }
}

function* saveCard(action: PayloadAction<Card | null>) {
  try {
    if (action.payload?.ID) {
      const card = yield call<any>(uploadImages, action.payload);
      const res = yield call<any>(updateCard, card);
      if (res) {
        yield call<any>(notify, "editor.save-success", "success");
        yield put({ type: SAVE_CARD_SUCCESS, payload: card });
      } else {
        yield call<any>(notify, "editor.save-error", "error");
        yield put({ type: SAVE_CARD_ERROR, payload: res });
      }
    }
  } catch (e) {
    yield put({ type: SAVE_CARD_ERROR, payload: e.message });
  }
}

function* saveCompany(action: PayloadAction<Company | null>) {
  try {
    if (action.payload?.ID) {
      const company = yield call<any>(updateCompany, action.payload);
      if (company) {
        yield call<any>(notify, "editor.save-success", "success");
        yield put({ type: SAVE_COMPANY_SUCCESS, payload: company });
      } else {
        yield call<any>(notify, "editor.save-error", "error");
        yield put({ type: SAVE_COMPANY_ERROR, payload: company });
      }
    }
  } catch (e) {
    yield put({ type: SAVE_COMPANY_ERROR, payload: e.message });
  }
}

function* applicationSaga() {
  yield takeLatest(LOAD_RESOURCE_REQUEST, loadResource);

  yield takeLatest(SAVE_CARD_REQUEST, saveCard);
  yield takeLatest(SAVE_COMPANY_REQUEST, saveCompany);
}

export default applicationSaga;
