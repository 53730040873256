import React from "react";
import styles from "./BgLoader.module.scss";

export default function bgloader(): any {
  return (
    <div
      className="w-full h-screen absolute top-0 left-0 z-50 opacity-90"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <div className={`${styles.gkloader} flex items-center justify-center`}>
        <p style={{ color: "transparent" }}>Loading...</p>
      </div>
    </div>
  );
}
