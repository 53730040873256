import React from "react";
import "./layouts.css";

export const Sidebar = ({ width = 300, children }: any) => {
  return (
    <div className="layout-sidebar" style={{ width: width, height: "100%" }}>
      {children}
    </div>
  );
};
