import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import "./templateEditor.css";
import { Card } from "../../models/card";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import {
  getCurrentCard,
  isLoading,
  loadResourceAction,
  setCurrentSectionAction,
} from "../../store/application";
import { compose } from "redux";
import { withRouter } from "react-router";
import { FideliumEditor } from "./fideliumEditor/fideliumEditor";
import { CorporateEditor } from "./corporateEditor/corporateEditor";

interface State {
  expand: boolean;
  error: string;
  previewAlias: string;
  loading: boolean;
  saving: boolean;
}

interface Props extends WithTranslation {
  currentSection?: string;
  currentCard?: Card | null;
  loading?: boolean;
  setCurrentSection?: any;
  loadResource?: any;
  saveCard?: any;
  updateCard?: any;
  language: string;
  match?: any;
  location?: any;
  history?: any;
}

class _TemplateEditor extends React.Component<Props, State> {
  private id: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      expand: false,
      error: "",
      previewAlias: "",
    };

    this.id = this.props.match.params.id;
  }



  async componentDidMount() {
    const { loadResource, setCurrentSection, i18n, language } = this.props;
    await i18n.changeLanguage(language);
    setCurrentSection("config");
    loadResource(this.id);
    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.loading === true &&
      this.props.loading === false &&
      this.props.currentCard
    ) {
      const evt = new CustomEvent("General.SetSpinner", { detail: false });
      window.dispatchEvent(evt);
    }

    if (prevProps.language !== this.props.language) {
      const { i18n, language } = this.props;
      await i18n.changeLanguage(language);
    }
  }

  componentWillUnmount() {}

  render() {
    const { currentCard = null, t } = this.props;

    if (currentCard?.Type === "master:fidelium") {
      return <FideliumEditor />;
    } else if (currentCard?.Type === "master:corporate") {
      return <CorporateEditor />;
    }

    return null;
  }
}

const mapStateToProps = (state: StoreState) => {
  const { application } = state;
  return {
    currentCard: getCurrentCard(application),
    loading: isLoading(application),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentSection: (section: string) =>
    dispatch(setCurrentSectionAction(section)),
  loadResource: (resourceId: number) =>
    dispatch(loadResourceAction(resourceId)),
});

// @ts-ignore
export const TemplateEditor = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(_TemplateEditor as any));
