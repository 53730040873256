import React from "react";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { getIn, setIn } from "../../utils";

export const TextField = ({
  values,
  label,
  name,
  help,
  placeholder = "",
  disabled = false,
}: any) => {
  const dispatch = useDispatch();
  const value = getIn(values, name, "");

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.target.value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <>
      <div className="p-field p-grid">
        {label && (
          <label
            htmlFor={name}
            className="p-col-fixed"
            style={{ width: "120px" }}
          >
            {label}
          </label>
        )}
        <div className="p-col">
          <InputText
            id={name}
            name={name}
            style={{ width: 400 }}
            tooltip={help}
            tooltipOptions={{ position: "right" }}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
};
