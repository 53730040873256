import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { getIn, setIn } from "../../utils";
import { Tooltip } from "primereact/tooltip";

export const CheckboxField = ({ label, name, help, values, disabled }: any) => {
  const dispatch = useDispatch();
  const checked = getIn(values, name, false);
  const [cv, setCV] = useState(checked);

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.currentTarget.checked);
    dispatch(updateCurrentCardAction(response));
    setCV(e.currentTarget.checked);
  };

  return (
    <>
      <div className="p-field p-grid">
        <label
          htmlFor={name}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
        <div className="p-col">
          <Tooltip target=".checkbox">{help}</Tooltip>
          <input
            className={"checkbox"}
            type="checkbox"
            checked={cv}
            onChange={handleOnChange}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
