export const Sectors = [
  {
    label: "editor.sector1",
    value: "editor.sector1",
  },
  { label: "editor.sector2", value: "editor.sector2" },
  { label: "editor.sector3", value: "editor.sector3" },
  {
    label: "editor.sector4",
    value: "editor.sector4",
  },
  { label: "editor.sector5", value: "editor.sector5" },
  { label: "editor.sector6", value: "editor.sector6" },
  {
    label: "editor.sector7",
    value: "editor.sector7",
  },
  { label: "editor.sector8", value: "editor.sector8" },
  {
    label: "editor.sector9",
    value: "editor.sector9",
  },
  {
    label: "editor.sector10",
    value: "editor.sector10",
  },
  {
    label: "editor.sector11",
    value: "editor.sector11",
  },
  { label: "editor.sector12", value: "editor.sector12" },
  {
    label: "editor.sector13",
    value: "editor.sector13",
  },
  {
    label: "editor.sector14",
    value: "editor.sector14",
  },
  {
    label: "editor.sector15",
    value: "editor.sector15",
  },
  {
    label: "editor.sector16",
    value: "editor.sector16",
  },
  {
    label: "editor.sector17",
    value: "editor.sector17",
  },
  {
    label: "editor.sector18",
    value: "editor.sector18",
  },
  { label: "editor.sector19", value: "editor.sector19" },
  {
    label: "editor.sector20",
    value: "editor.sector20",
  },
  {
    label: "editor.sector21",
    value: "editor.sector21",
  },
];
