import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

import "./styles.css";
import {
  CheckboxField,
  DropdownField,
  EmailField,
  TextArea,
  TextField,
} from "../../components/inputs";
import { Sectors } from "../data/sectors";
import { TagsField } from "../../components/inputs/TagsField";
import { ImageUploaderField } from "../../components/inputs/ImageUploaderField";
import { AliasField } from "../../components/inputs/AliasField";
import { WithTranslation, withTranslation } from "react-i18next";
import { Card as PrimeCard } from "primereact/card";
import { Card } from "../../models/card";
import { Button } from "primereact/button";
import { confirmPopup } from "primereact/confirmpopup";
import { CardFactory } from "../../models/card/CardFactory";

interface ConfigEditorProps extends WithTranslation {
  onChange: any;
  card: Card | null;
  isAdmin: boolean;
  isUser: boolean;
  onAliasChanged: any;
  saveCard: any;
  onSendAssignationEmail: any;
}

interface State {
  isError: boolean;
}

class _ConfigEditor extends React.Component<ConfigEditorProps, State> {
  constructor(props: ConfigEditorProps) {
    super(props);

    this.state = {
      isError: false,
    };

    this.handleClearCard = this.handleClearCard.bind(this);
  }

  handleClearCard(event: any) {
    const { t, card, onChange, saveCard } = this.props;

    confirmPopup({
      message: t("editor.clear-warning"),
      icon: "pi pi-info-circle",
      acceptLabel: t("editor.clear-button"),
      rejectLabel: t("editor.cancel"),
      acceptClassName: "p-button-danger",
      target: event.currentTarget,
      accept: () => {
        const nc = CardFactory.clearCard(card);
        onChange(nc);
        saveCard(nc);
      },
      reject: () => {},
    });
  }

  handleCopyLink = () => {
    const { card } = this.props;
    const link = `https://v2.gkd.es/${card.Alias}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
    } else {
      // Fallback for browsers that don't support the Clipboard API
      const textarea = document.createElement('textarea');
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  };


  render() {
    const { t, i18n, card, isAdmin = false, isUser } = this.props;
    const showSubordinate = card?.Type.startsWith("sub:") && isAdmin;
    const showConfig = isConfigurable(card, isUser, isAdmin);
    const contactLocked = isContactLocked(card, isUser, isAdmin);
    const searchsLocked = isSearchsLockedLocked(card, isUser, isAdmin);

    return (
      <>
        <PrimeCard>
          <Accordion multiple activeIndex={0}>
            {showSubordinate && (
              <AccordionTab header={t("editor.subordinate")}>
                <p style={{textAlign: "left",  marginBottom: "10px" }}>Asignada a </p>
                <TextField
                  label={"Nombre"}
                  values={card}
                  name={"Assignation.Name"}
                />

                <div style={{display: "flex"}}>
                <EmailField
                  label={"Email"}
                  values={card}
                  name={"Assignation.Email"}
                />
                  <Button
                    label={t("editor.card-assign")}
                    className="tutor-button-save p-button-raised"
                    onClick={this.props.onSendAssignationEmail}
                    disabled={false}
                    style={{marginBottom: "20px", marginLeft: "10px"}}
                  />
                </div>
              </AccordionTab>
            )}
            {showConfig && card.Type !== "master:corporate" && (
              <AccordionTab header={t("editor.alias")}>
                <p style={{ textAlign: "left" }}>{t("editor.info1")}</p>
                <p style={{ textAlign: "left", marginBottom: 10 }}>
                  <span style={{ color: "red", fontStyle: "italic" }}>
                    {t("editor.info2")}
                  </span>
                </p>
                <AliasField
                  label={t("editor.name")}
                  values={card}
                  onAliasChanged={this.props.onAliasChanged}
                />
                {
                  card && card.Alias &&
                  <div style={{textAlign: 'left'}}>
                    <a target="_blank" href={`https://v2.gkd.es/${card.Alias}`}>Ir a la tarjeta</a>
                    <span style={{marginLeft: "10px", cursor: "pointer"}} className="pi pi-copy" onClick={this.handleCopyLink}></span>
                  </div>
                }
              </AccordionTab>
            )}
            {showConfig && (
              <AccordionTab header={t("editor.icon")}>
                <p style={{ textAlign: "left", marginBottom: 10 }}>
                  {t("editor.icon-info")}
                </p>
                <ImageUploaderField
                  values={card}
                  label={t("editor.icon")}
                  id="Manifest.Icon"
                  name="Manifest.Icon"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <p style={{textAlign: "left" , fontStyle: "italic"}}>{t('editor.icon-recomendation')}</p>
              </AccordionTab>
            )}
            {isAdmin && (
              <AccordionTab header={t("editor.social-icon")}>
                <p style={{ textAlign: "left", marginBottom: 10 }}>
                  {t("editor.social-icon-info")}
                </p>

                <p style={{ textAlign: "left", fontStyle: "italic" }}>{t("editor.social-icon-recomendation")}</p>
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-email")}
                  id="CustomIcons.Email"
                  name="CustomIcons.Email"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-facebook")}
                  id="CustomIcons.Facebook"
                  name="CustomIcons.Facebook"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-instagram")}
                  id="CustomIcons.Instagram"
                  name="CustomIcons.Instagram"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-linkedin")}
                  id="CustomIcons.Linkedin"
                  name="CustomIcons.Linkedin"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-mobile")}
                  id="CustomIcons.Mobile"
                  name="CustomIcons.Mobile"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-reddit")}
                  id="CustomIcons.Reddit"
                  name="CustomIcons.Reddit"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-sms")}
                  id="CustomIcons.SMS"
                  name="CustomIcons.SMS"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-corporate")}
                  id="CustomIcons.Corporate"
                  name="CustomIcons.Corporate"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-telegram")}
                  id="CustomIcons.Telegram"
                  name="CustomIcons.Telegram"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-tiktok")}
                  id="CustomIcons.Tiktok"
                  name="CustomIcons.Tiktok"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-twitter")}
                  id="CustomIcons.Twitter"
                  name="CustomIcons.Twitter"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-web")}
                  id="CustomIcons.Web"
                  name="CustomIcons.Web"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-whatsapp")}
                  id="CustomIcons.Whatsapp"
                  name="CustomIcons.Whatsapp"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-youtube")}
                  id="CustomIcons.Youtube"
                  name="CustomIcons.Youtube"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-address")}
                  id="CustomIcons.Address"
                  name="CustomIcons.Address"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />
                <ImageUploaderField
                  values={card}
                  label={t("editor.social-icon-share")}
                  id="CustomIcons.Share"
                  name="CustomIcons.Share"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />

              </AccordionTab>
            )}
              <AccordionTab header={t("editor.custom-qr")}>
                <p style={{ textAlign: "left", marginBottom: 10 }}>
                  {t("editor.custom-qr-info")}
                </p>

                <p style={{ textAlign: "left", fontStyle: "italic" }}>{t("editor.custom-qr-recomendation")}</p>
                <ImageUploaderField
                  values={card}
                  label={t("editor.custom-qr")}
                  id="CustomQR"
                  name="CustomQR"
                  style={{ width: 400 }}
                  round={false}
                  className={""}
                />

              </AccordionTab>

            {showConfig && (
              <AccordionTab header={t("editor.contact-data")} disabled={contactLocked}>
              <p style={{ textAlign: "left", marginBottom: "20px" }}>{t("editor.info3")}</p>
                {card.Type === "master:corporate" && isAdmin &&
                  <CheckboxField label={t("editor.block-contact")} name="Contact.Locked" values={card} />
                }
                <ImageUploaderField
                  values={card}
                  label={t("editor.user-image")}
                  id="Contact.UserImage"
                  name="Contact.UserImage"
                  style={{ width: 400 }}
                  round={true}
                  className={""}
                />
                <p style={{ textAlign: "left", marginBottom: "20px", fontStyle: "italic" }}>{t("editor.user-image-warning")}</p>
                <TextField
                  label={t("editor.name")}
                  name="Contact.FirstName"
                  values={card}
                />
                <TextField
                  label={t("editor.lastname")}
                  name="Contact.LastName"
                  values={card}
                />
                <TextField
                  label={t("editor.company")}
                  name="Contact.Company"
                  values={card}
                />
                <TextField
                  label={t("editor.charge")}
                  name="Contact.Charge"
                  values={card}
                />
                <EmailField
                  label={t("editor.email")}
                  name="Contact.Email"
                  values={card}
                />
                <TextField
                  label={t("editor.mobile")}
                  name="Contact.Phone"
                  values={card}
                />
                <TextField
                  label={t("editor.company-phone")}
                  name="Contact.CorporatePhone"
                  values={card}
                />
                <TextField
                  label={t("editor.web")}
                  name="Contact.WebUrl"
                  values={card}
                />
                <TextArea
                  label={t("editor.address")}
                  name="Contact.Address"
                  values={card}
                />
              </AccordionTab>
            )}
            {showConfig && (
              <AccordionTab header={t("editor.social-networks")} disabled={contactLocked}>
                <p style={{ textAlign: "left" }}>{t("editor.info4")}</p>
                <TextField
                  label="Facebook"
                  name="Contact.Social.Facebook"
                  values={card}
                  help={t("editor.tooltip-facebook")}
                />
                <TextField
                  label="LinkedIn"
                  name="Contact.Social.Linkedin"
                  values={card}
                  help={t("editor.tooltip-linkedin")}
                />
                <TextField
                  label="Twitter"
                  name="Contact.Social.Twitter"
                  values={card}
                  help={t("editor.tooltip-twitter")}
                />
                <TextField
                  label="Instagram"
                  name="Contact.Social.Instagram"
                  values={card}
                  help={t("editor.tooltip-instagram")}
                />
                <TextField
                  label="Telegram"
                  name="Contact.Social.Telegram"
                  values={card}
                  help={t("editor.tooltip-telegram")}
                />
                <TextField
                  label="Youtube"
                  name="Contact.Social.Youtube"
                  values={card}
                  help={t("editor.tooltip-youtube")}
                />
                <TextField
                  label="TikTok"
                  name="Contact.Social.Tiktok"
                  values={card}
                  help={t("editor.tooltip-tiktok")}
                />
                <TextField
                  label="Reddit"
                  name="Contact.Social.Reddit"
                  values={card}
                  help={t("editor.tooltip-reddit")}
                />
              </AccordionTab>
            )}
            {showConfig && (
              <AccordionTab header={t("editor.searches")} disabled={searchsLocked}>
                {card.Type === "master:corporate" && isAdmin &&
                  <CheckboxField label={t("editor.block-searchs")} name="Search.Locked" values={card} />
                }
                <CheckboxField
                  label={t("editor.public")}
                  name="Search.Public"
                  values={card}
                  help={t("editor.tooltip-public")}
                />
                <DropdownField
                  items={Sectors}
                  label={t("editor.main-sector")}
                  name="Search.Sector"
                  values={card}
                  help={t("editor.tooltip-sector")}
                />
                <TagsField
                  label="Tags"
                  name="Search.Tags"
                  values={card}
                  help={t("editor.tooltip-tags")}
                />
              </AccordionTab>
            )}
            { isAdmin &&
              <AccordionTab header={t("editor.advanced")}>
                <TextArea
                  label="Css"
                  name="CustomCss"
                  values={card}
                  style={{ width: "100%", borderColor: "red" }}
                />
                <p style={{ textAlign: "left", marginBottom: "20px", color: "red" }}>{t("editor.advanced-warning")}</p>
                <TextArea
                  label="Js"
                  name="CustomJs"
                  values={card}
                  style={{ width: "100%", borderColor: "red" }}
                />
                <p style={{ textAlign: "left", color: "red" }}>{t("editor.advanced-warning")}</p>
              </AccordionTab>}

            {isAdmin && <AccordionTab
              header={t("editor.clear")}
              headerClassName="accordion-tab-red"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  {t("editor.clear-info")}
                </p>
                <Button
                  label={t("editor.clear-button")}
                  className="p-button-raised p-button-danger"
                  onClick={this.handleClearCard}
                  loading={false}
                />
              </div>
            </AccordionTab> }
          </Accordion>
        </PrimeCard>
      </>
    );
  }
}

export const ConfigEditor = withTranslation()(_ConfigEditor);

function isConfigurable(card: Card, isUser: boolean, isAdmin: boolean) {
  if (card.Type === "card:professional") {
    return true;
  }

  if (card.Type === "sub:fidelium" && isUser) {
    return true;
  }

  if (card.Type === "master:corporate" && isAdmin) {
    return true;
  }

  if (card.Type === "sub:corporate") {
    return true;
  }

  return false;
}

function isContactLocked(card: Card, isUser: boolean, isAdmin: boolean) {
  if (card.Type === "sub:corporate" && isUser && card?.Mastercard?.Contact?.Locked) {
    return true;
  }

  return false;
}

function isSearchsLockedLocked(card: Card, isUser: boolean, isAdmin: boolean) {
  if (card.Type === "sub:corporate" && isUser && card?.Mastercard?.Search?.Locked) {
    return true;
  }

  return false;
}
