import Dropzone from "react-dropzone";
import React, { useState } from "react";
import { useHovering } from "use-hovering";
import "./uploaders.css";
import { CropModal } from "../cropModal/cropModal";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from "primereact/progressbar";
import { v4 as uuidv4 } from "uuid";
import { readFile } from "../../service/files";
import { gkb } from "../../service/gkb";

const MAX_FILE_SIZE = 100 * 1048576; // 1MB

const initialCropperjsOptions = {
  initialAspectRatio: 1,
  aspectRatio: 1,
  guides: false,
  viewMode: 1,
};

const outputOptions = {
  width: 600,
  height: 600,
  minWidth: 600,
  minHeight: 600,
  maxWidth: 600,
  maxHeight: 600,
  imageSmoothingEnabled: "false",
  imageSmoothingQuality: "high",
};

const PlaceHolder = ({ placeholder }: any) => {
  return (
    <div className={"placeholder-wrapper"}>
      {placeholder && placeholder !== "" && (
        <img
          src={placeholder}
          className={"placeholder-image"}
          alt={"placeholder"}
        />
      )}
      <i className="pi pi-camera" style={{ fontSize: "3em" }} />
    </div>
  );
};

export const LogoUploader = ({
  onChange,
  name,
  value,
  round,
  placeholder,
  cropperjsOptions = {},
}: any) => {
  const ref = React.useRef<any>();
  const isHovered = useHovering(ref);
  const cropOptions = { ...initialCropperjsOptions, ...cropperjsOptions };

  const [showCrop, setShowCrop] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const deleteImage = () => {
    onChange({ target: { value: null, name } });
    setFiles([]);
  };

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setLoading(true);
      const file: any = await readFile(acceptedFiles[0]);

      file.uuid = uuidv4();
      setFiles([file]);
      setShowCrop(true);

      setLoading(false);
    }
  };

  let imageContent = "";
  if (value) {
    if (typeof value === "string") {
      imageContent = value;
    } else {
      imageContent = value[0].content;
    }
  }

  return (
    <div className="avatar-drop-zone" ref={ref}>
      {value?.length > 0 ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className="drop-target-delete-button flex"
          onClick={deleteImage}
          style={{ transform: "translate(85px, -10px)" }}
        >
          <i
            className="pi pi-times"
            style={{ fontSize: "16px", color: "white" }}
          />
        </div>
      ) : (
        <div className="drop-target-delete-button" style={{ width: 0 }} />
      )}
      {loading && (
        <div
          className="gallery-spinner"
          style={{
            borderRadius: round ? "50%" : "4px",
            height: 100,
            width: 100,
          }}
        >
          <ProgressSpinner style={{ transform: "scale(0.6)" }} />
        </div>
      )}
      {!loading && (
        <Dropzone
          onDrop={onDrop}
          maxFiles={1}
          maxSize={MAX_FILE_SIZE}
          multiple={false}
          noDragEventsBubbling={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={"h-full"} style={{ marginTop: -25 }}>
              <div
                className={`avatar-drop-target ${
                  isHovered ? "avatar-drop-target-hover" : ""
                }`}
                style={{ borderRadius: round ? "50%" : "4px" }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {(value?.length === 0 || !value) && (
                  <PlaceHolder placeholder={placeholder} />
                )}
                {value?.length > 0 && (
                  <img
                    src={imageContent}
                    width={98}
                    height={98}
                    alt="logo"
                    style={{ borderRadius: round ? "50%" : "4px" }}
                  />
                )}
              </div>
            </div>
          )}
        </Dropzone>
      )}
      <CropModal
        width={600}
        show={showCrop}
        files={files}
        round={round}
        onCancel={() => {
          setShowCrop(false);
        }}
        onAcept={async (file: any) => {
          setUploadProgress(0);
          setUploading(true);
          setShowCrop(false);

          if (typeof file.content === "string") {
            onChange({ target: { value: file.content, name } });
            setFiles([file]);
          } else {
            const response = await gkb.UploadFiles(
              file.content,
              "",
              (progress: number) => {
                if (progress >= 100) {
                  setUploading(false);
                  setUploadProgress(0);
                } else {
                  setUploadProgress(progress);
                }
              }
            );
            if (response && response.status === 201) {
              onChange({ target: { value: response.data.url, name } });
              setFiles([response.data]);
            }
          }
          setUploading(false);
        }}
        croperjsOptions={cropOptions}
        outputOptions={outputOptions}
      />
      {uploading && (
        <ProgressBar
          value={uploadProgress}
          showValue={false}
          style={{ height: 5, marginTop: 5 }}
        />
      )}
    </div>
  );
};
