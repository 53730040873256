import React, { createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import cookie from "js-cookie";

import "./fideliumEditor.css";
import { Empty } from "./empty";
import { Card, SectionData } from "../../../models/card";
import { Content, LayoutWrapper, Sidebar } from "../../../components/layouts";
import { connect } from "react-redux";
import { StoreState } from "../../../store";
import {
  isLoading,
  setCurrentSectionAction,
  getCurrentSection,
  loadResourceAction,
  updateCurrentCardAction,
  saveCardAction,
  getCurrentCard,
  getCurrentUserId,
  getCurrentUser,
  updateUserAction,
} from "../../../store/application";
import { Button } from "primereact/button";
import { htmlToPlainText } from "../../../models/textversion";
import { compose } from "redux";
import { withRouter } from "react-router";
import { CardEditorMenu } from "../../components/menu/menu";
import { SectionEditor } from "../../components/sectionEditor";
import { ConfigEditor } from "../../components/configEditor";
import { TutorComponent } from "../../../tutor/TutorComponent";
import { gkb } from "../../../service/gkb";
import BgLoader from "../../../components/bgLoader/BgLoader";
import { cardPreviewBase } from "../../../utils";

interface State {
  expand: boolean;
  error: string;
  loading: boolean;
  saving: boolean;
  showTutor: boolean;
}

interface Props extends WithTranslation {
  currentSection?: string;
  currentCard?: Card | null;
  loading?: boolean;
  setCurrentSection?: any;
  saveCard?: any;
  updateCard?: any;
  updateUser?: any;
  language: string;
  match?: any;
  location?: any;
  history?: any;
  userId: number;
  user: any;
}

class _FideliumEditor extends React.Component<Props, State> {
  private ifRef = createRef<HTMLIFrameElement>();
  private id: string;
  private firstRun = true;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      expand: false,
      error: "",
      showTutor: false,
    };

    this.id = this.props.match.params.id;
    this.handleCardChange = this.handleCardChange.bind(this);
    this.handleCardSave = this.handleCardSave.bind(this);
    this.handlePostMessage = this.handlePostMessage.bind(this);
    this.handleTutorClose = this.handleTutorClose.bind(this);
    this.handleSendAssignationEmail = this.handleSendAssignationEmail.bind(this);
  }

  handleSendAssignationEmail() {
    debugger
    setTimeout(() => {
      gkb.AssignCard(this.props.currentCard?.ID).then((res) => {
        console.log("Enviado")
      });

    }, 1000);
  }

  async componentDidMount() {
    const { i18n, language, setCurrentSection } = this.props;
    await i18n.changeLanguage(language);
    setCurrentSection("config");

    window.addEventListener("message", this.handlePostMessage, false);
  }

  handlePostMessage(event: any) {
    if (typeof event.data === "string") {
      if (event.data === "reload") {
        window.location.reload();
        return;
      }
      if (event.data.startsWith("section:")) {
        if (event.data !== "section:0") {
          this.props.setCurrentSection(event.data);
        }
      }
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const { currentSection, user } = this.props;
    if (
      prevProps.loading === true &&
      this.props.loading === false &&
      this.props.currentCard
    ) {
      if (this.ifRef?.current) {
        this.ifRef.current.contentWindow.postMessage("reload", "*");
      }
      const evt = new CustomEvent("General.SetSpinner", { detail: false });
      window.dispatchEvent(evt);

      if (this.firstRun) {
        const st = cookie.get("gkb-editor-tutorial");
        if (!st || st === "0") {
          this.setState({ showTutor: true });
        }
        this.firstRun = false;
      }
    }

    if (prevProps.language !== this.props.language) {
      const { i18n, language } = this.props;
      await i18n.changeLanguage(language);
    }

    if (prevProps.currentSection !== currentSection) {
      this.gotoSection(currentSection);
    }
  }

  getPreviewUrl(cardId: number) {
    return `${cardPreviewBase}/cardid/${cardId}&ts=${Date.now()}`;
  }

  gotoSection(section: string) {
    if (section.startsWith("section:")) {
      this.ifRef.current.contentWindow.postMessage(section, "*");
    } else {
      this.ifRef.current.contentWindow.postMessage("section:0", "*");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handlePostMessage, false);
  }

  private async handleCardChange(changes: any) {
    const { updateCard } = this.props;
    updateCard(changes);
  }

  private handleCardSave() {
    const { saveCard, loading } = this.props;
    if (!loading) {
      saveCard(this.props.currentCard);
    }
  }

  findCardById(id: string) {
    const { currentCard } = this.props;
    if (currentCard?.Sections) {
      for (let i = 0; i < currentCard.Sections.length; i++) {
        if (currentCard.Sections[i].SectionID === id) {
          return currentCard.Sections[i];
        }
      }
    }
  }

  handleTutorClose() {
    this.setState({ showTutor: false });
    const user = { ...this.props.user, EditorTutorialViewed: true };
    this.props.updateUser(user);
    cookie.set("gkb-editor-tutorial", "1");
    gkb
      .UpdateApiResource({
        name: "accounts",
        id: user.ID,
        body: { EditorTutorialViewed: true },
      })
      .then()
      .catch();
  }

  renderBreadcrumbs() {
    const { currentCard = null, t, history } = this.props;

    return (
      <>
        <ul
          className={"breadcrumbs w-full h-10 flex text-left pb-4 text-lg"}
          style={{ color: "#B8CE75" }}
        >
          <li
            className={"underline hover:opacity-50 cursor-pointer"}
            onClick={() => {
              history.push(`/my-account/cards/`);
            }}
          >
            {t("editor.my-cards")}
          </li>
          <li className={"px-2"}>{" / "}</li>
          <li>
            {t("editor.master-organization") + ` #${currentCard.CompanyID}`}
          </li>
          <div className={"flex-1 text-right"}>
            <li
              className={"underline hover:opacity-50 cursor-pointer"}
              onClick={() => {
                history.push(
                  `/my-account/cards/${currentCard.CompanyID}/subordinates`
                );
              }}
            >
              {t("editor.goto-subordinates")}
            </li>
          </div>
        </ul>
        <div className={"w-full text-right"} style={{ flex: 1 }}>
          <i
            className={
              "text-white pr-2 tutor-icon-circle cursor-pointer hover:opacity-60 fal fa-info-circle text-2xl"
            }
            onClick={() => {
              this.setState({ showTutor: true });
            }}
          />
        </div>
      </>
    );
  }

  render() {
    const { loading, showTutor } = this.state;
    const {
      currentCard = null,
      t,
      user,
      currentSection = "",
      saveCard,
    } = this.props;
    const sectionId = currentSection.split(":")[1];
    const sectionData = this.findCardById(sectionId);
    if (loading) {
      return <BgLoader />;
    }

    return (
      <>
        <TutorComponent
          tutorialID={1}
          show={showTutor}
          onClose={this.handleTutorClose}
        />
        <LayoutWrapper>
          <Sidebar>
            <CardEditorMenu
              isUser={true}
              isAdmin={true}
              card={currentCard}
              onChange={this.handleCardChange}
            />
          </Sidebar>
          {currentCard?.Sections?.length > 0 && (
            <>
              <Content className={"overflow-y-auto bg-color-template"}>
                {this.renderBreadcrumbs()}
                <div className="card-editor-header">
                  {currentSection === "config" && (
                    <h3 className={"text-white text-2xl"}>
                      {t("editor.general")}
                    </h3>
                  )}
                  {currentSection.startsWith("section:") && sectionData && (
                    <h3 className={"text-white text-2xl"}>
                      Sección {htmlToPlainText(sectionData.Button.Text)}
                    </h3>
                  )}
                  <Button
                    label={t("editor.save")}
                    className="tutor-button-save p-button-raised"
                    onClick={this.handleCardSave}
                    loading={this.props.loading}
                  />
                </div>
                {currentSection === "config" && (
                  <ConfigEditor
                    card={currentCard}
                    onChange={this.handleCardChange}
                    isAdmin={true}
                    isUser={true}
                    onAliasChanged={() => {}}
                    saveCard={saveCard}
                    onSendAssignationEmail={this.handleSendAssignationEmail}
                  />
                )}
                {currentCard.Sections.length > 0 &&
                  currentCard.Sections.map((s: SectionData) => (
                    <SectionEditor
                      key={s.SectionID}
                      card={currentCard}
                      section={s.SectionID}
                      onChange={this.handleCardChange}
                      visible={currentSection === `section:${s.SectionID}`}
                    />
                  ))}
              </Content>
              <Sidebar width={500}>
                <iframe
                  className="tutor-card-preview"
                  id="gkiframe"
                  ref={this.ifRef}
                  title="GreenKard"
                  src={`${cardPreviewBase}/cardid/${currentCard?.ID}`}
                  style={{
                    width: "100%",
                    height: "calc(100vh - 80px)",
                  }}
                />
              </Sidebar>
            </>
          )}
          {currentCard?.Sections?.length === 0 && (
            <>
              <div
                className={
                  "overflow-y-auto flex justify-center w-full flex-col items-center"
                }
                style={{ padding: 20 }}
              >
                <p className={"text-2xl text-green-600"}>
                  {t("template-empty.text1")}
                </p>
                <p className={"text-lg text-gray-600 mt-10"}>
                  {t("template-empty.clickin")}{" "}
                  <span className={"italic"} style={{ color: "#047857" }}>
                    {t("editor.new-section")}
                    Nueva Sección
                  </span>{" "}
                  {t("template-empty.text2")}
                </p>
                <div className={"mt-10"}>
                  <Empty width={400} />
                </div>
              </div>
            </>
          )}
        </LayoutWrapper>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState) => {
  const { application } = state;
  return {
    // currentResource: getCurrentResource(application),
    currentCard: getCurrentCard(application),
    currentSection: getCurrentSection(application),
    loading: isLoading(application),
    userId: getCurrentUserId(application),
    user: getCurrentUser(application),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentSection: (section: string) =>
    dispatch(setCurrentSectionAction(section)),
  loadResource: (resourceId: number) =>
    dispatch(loadResourceAction(resourceId)),
  updateCard: (card: Card) => dispatch(updateCurrentCardAction(card)),
  saveCard: (card: Card) => dispatch(saveCardAction(card)),
  updateUser: (user: any) => dispatch(updateUserAction(user)),
});

// @ts-ignore
export const FideliumEditor = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(_FideliumEditor as any));
