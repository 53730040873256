import "./inputs.css";
import { useDispatch } from "react-redux";
import { getIn, setIn } from "../../utils";
import { updateCurrentCardAction } from "../../store/application";
import { Editor } from "@tinymce/tinymce-react";
import React,  { useRef } from "react";
import { gkb } from "../../service/gkb";

export const TinyMCEEditorField = (props: any) => {
  const tinymce: any = useRef();
  const { name, values, label } = props;
  const editorName = props.name
    .replace(/\./g, "")
    .replace(/\[/g, "")
    .replace(/\]/g, "");

  const dispatch = useDispatch();
  const value = getIn(values, name, "");

  function imageUploader(blobInfo, success, failure) {
    let blobUrl = "";
    let fn = "";
    let cardID = values.ID;
    if (typeof blobInfo === "string") {
      blobUrl = blobInfo;
    } else {
      blobUrl = blobInfo.blobUri();
      fn = blobInfo.filename();
    }

    gkb
      .UploadBlob(blobUrl, cardID, fn)
      .then((res) => {
        success(res);
      })
      .catch((err) => {
        failure(err);
      });
  }

  const handleOnChange = (value: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, value);
    dispatch(updateCurrentCardAction(response));
  };

  return (
    <div className="p-field p-grid">
      {label && label !== "" && (
        <label
          htmlFor={editorName}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
      )}
      <div className="p-col">
        <Editor
          ref={tinymce}
          value={value}
          apiKey="fig2jq5n25j06wo5n25eca35v22afaikh3pnaztr960x5u5a"
          init={{
            height: 500,
            menubar: false,
            toolbar_mode: "wrap",
            paste_data_images: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime table paste code preview media",
            ],
            toolbar: `undo redo | formatselect | fontselect | table | bold italic underline backcolor forecolor |
             alignleft aligncenter alignright alignjustify |
             bullist numlist outdent indent | removeformat | link image media | code | preview`,

            /* File Picker options */
            file_picker_types: "image media",
            file_picker_callback: function (cb, value, meta) {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              if (meta.filetype === "media") {
                input.setAttribute("accept", "video/*");
              } else if (meta.filetype === "image") {
                input.setAttribute("accept", "image/*");
              }

              input.onchange = function (event: any) {
                const file = event.currentTarget.files[0];
                const reader = new FileReader();

                reader.onload = function () {
                  const id = "blobid" + new Date().getTime();
                  const blobCache =
                    tinymce.current.editor.editorUpload.blobCache;
                  const base64 = (reader.result as string).split(",")[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  sessionStorage.setItem(blobInfo.blobUri(), file.name);

                  // call the callback and populate the Title field with the file name
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            },
            /* Images uploader options */
            automatic_uploads: true,
            images_upload_handler: imageUploader,
            /* Videos uploader options */
            // media_url_resolver: function (data, resolve, reject) {
            //   debugger;
            //   if (data.url.startsWith("http")) {
            //     const embedHtml = `<p><video controls="controls" width="300" height="150">
            //           <source src="${data.url}" /></video></p>`;
            //     resolve({ html: embedHtml });
            //     return;
            //   }
            //   imageUploader(
            //     data.url,
            //     (res) => {
            //       const embedHtml = `<p><video controls="controls" width="300" height="150">
            //           <source src="${res}" /></video></p>`;
            //       resolve({ html: embedHtml });
            //     },
            //     reject
            //   );
            // },
            media_alt_source: false,
          }}
          onEditorChange={handleOnChange}
        />
      </div>
    </div>
  );
};
