import React from "react";
import { useTranslation } from "react-i18next";

export const SkipComponent = () => {
  const { t } = useTranslation();
  return (
    <strong aria-label="skip" className={"text-gray-600"}>
      {t("tutor.omit")}
    </strong>
  );
};

export const ContentComponent = ({ content }) => {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

const NextComponent = () => {
  const { t } = useTranslation();
  return (
    <span aria-label="next" className={"text-white"}>
      {t("tutor.next")}
    </span>
  );
};

const BackComponent = () => {
  const { t } = useTranslation();
  return <span aria-label="back">{t("tutor.back")}</span>;
};

const LastComponent = () => {
  const { t } = useTranslation();
  return <span aria-label="last">{t("tutor.last")}</span>;
};

export const Locale = {
  skip: <SkipComponent />,
  next: <NextComponent />,
  back: <BackComponent />,
  last: <LastComponent />,
};
