import Dropzone from "react-dropzone";
import React, { useState } from "react";
import { useHovering } from "use-hovering";
import "./uploaders.css";
import { readFile } from "../../service/files";
import { ProgressSpinner } from "primereact/progressspinner";
import { v4 as uuidv4 } from "uuid";

const MAX_FILE_SIZE = 20 * 1048576; // 20MB

export const DocumentsUploader = ({ onAddDocuments }: any) => {
  const ref = React.useRef<any>();
  const isHovered = useHovering(ref);

  const [loading, setLoading] = useState(false);

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setLoading(true);
      const reading = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const pr = readFile(acceptedFiles[i]);
        reading.push(pr);
      }

      const results = await Promise.allSettled(reading);
      const files = [];
      for (let i = 0; i < results.length; i++) {
        if (results[i].status === "fulfilled") {
          const file = (results[i] as any).value;
          file.uuid = uuidv4();
          files.push(file);
        }
      }

      onAddDocuments(files);
      setLoading(false);
    }
  };

  return (
    <div
      className="gallery-drop-zone"
      style={{ display: "inline-block" }}
      ref={ref}
    >
      {loading && (
        <div className="gallery-spinner">
          <ProgressSpinner style={{ transform: "scale(0.6)" }} />
        </div>
      )}
      {!loading && (
        <Dropzone
          onDrop={onDrop}
          maxFiles={0}
          maxSize={MAX_FILE_SIZE}
          multiple={true}
          noDragEventsBubbling={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={`gallery-drop-target ${
                isHovered ? "gallery-drop-target-hover" : ""
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <i className="pi pi-upload" style={{ fontSize: "1.5em" }}></i>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};
