import React from "react";
import { Dropdown } from "primereact/dropdown";
import { useDispatch } from "react-redux";
import { updateCurrentCardAction } from "../../store/application";
import { getIn, setIn } from "../../utils";
import { useTranslation } from "react-i18next";

export const DropdownField = ({
  items,
  values,
  label,
  name,
  help,
  def,
}: any) => {
  const dispatch = useDispatch();
  let value = getIn(values, name, "");
  if (!value) {
    value = def;
  }
  const { t } = useTranslation();

  const handleOnChange = (e: any) => {
    const nc = { ...values };
    const response = setIn(nc, name, e.value);
    dispatch(updateCurrentCardAction(response));
  };

  const its = items
    .map((item) => ({
      value: item.value,
      label: t(item.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <div className="p-field p-grid">
        <label
          htmlFor={name}
          className="p-col-fixed"
          style={{ width: "120px" }}
        >
          {label}
        </label>
        <div className="p-col">
          <Dropdown
            id={name}
            name={name}
            value={value}
            style={{ width: 400 }}
            tooltip={help}
            tooltipOptions={{ position: "right" }}
            options={its}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
};
